import { replaceAtIndex } from '../libs/helpers';
import {
  CONVERSATION_CREATED,
  CONVERSATION_READ_ONE,
  CONVERSATION_READ_ALL,
  MESSAGE_CREATED,
  MESSAGES_READ,
  MESSAGE_MARKED_READ,
  UNREAD_COUNT_RECEIVED,
  TOP_MESSAGES_READ,
  RESET_MESSAGES,
  UNREAD_COUNT_RECEIVED_QUOTES,
  CONVERSATION_READ_ALL_UNREAD,
  CONVERSATION_READ_ALL_ARCHIVED,
  CONVERSATION_READ_ALL_SUPPLIER_V2,
  MESSAGE_CREATED_V1,
} from '../constants/actions.realtime';

const initialState = {
  conversationId: null,
  threads: [],
  messages: [],
  topMessages: [],
  unreadCount: 0,
};

const noop = () => ({});

const socketReducer = ({
  key = '',
  onSend = noop,
  onReceive = noop,
  onError = noop,
}) => {
  const loadingKey = `loading${key}`;
  const errorKey = `error${key}`;

  return (state, action) => {
    if (!action.__FROM_SOCKET__) {
      return {
        ...state,
        ...onSend(state, action),
        [loadingKey]: true,
        [errorKey]: null,
      };
    }

    if (action.data.error) {
      return {
        ...state,
        ...onError(state, action),
        [loadingKey]: false,
        [errorKey]: action.data.error,
      };
    }

    return {
      ...state,
      ...onReceive(state, action),
      [loadingKey]: false,
      [errorKey]: null,
    };
  };
};

const onCreateConversation = socketReducer({
  key: 'CreateConversation',
  onReceive: (state, { data }) => ({
    threads: [...state.threads, data],
  }),
});

const onReadOneConversation = socketReducer({
  key: 'Conversation',
  onReceive: (state, { data }) => {
    const existingThreadIndex = state.threads.findIndex(
      ({ uuid }) => uuid === data.uuid
    );

    return {
      threads:
        existingThreadIndex > -1
          ? replaceAtIndex(state.threads, existingThreadIndex, {
              ...state.threads[existingThreadIndex],
              ...data,
            })
          : [...state.threads, data],
    };
  },
});

const onAllInboxConversations = socketReducer({
  key: 'ConversationList',
  onReceive: (state, { data }) => ({
    threads: data.threads,
    total: data.total,
  }),
});

const onAllUnreadConversations = socketReducer({
  key: 'UnreadConversations',
  onReceive: (state, { data }) => ({
    threads: data.threads,
    total: data.total,
  }),
});

const onAllArchivedConversations = socketReducer({
  key: 'ArchivedConversations',
  onReceive: (state, { data }) => {
    return {
      threads: data.threads,
      total: data.total,
    };
  },
});

const onAllSupplierConversations = socketReducer({
  key: 'SupplierAllMessages',
  onReceive: (state, { data }) => ({
    threads: data.threads,
    total: data.total,
  }),
});

const onMessageCreated = socketReducer({
  key: 'CreateMessage',
  onReceive: (state, { data }) => {
    if (!state.threads.some(({ uuid }) => uuid === data.conversationId)) {
      return {};
    }

    const existingMessageIndex = state.messages.findIndex(
      ({ uuid }) => uuid === data.uuid
    );

    const createdDate = new Date(data.created);

    return {
      threads: replaceAtIndex(
        state.threads,
        state.threads.findIndex(
          thread =>
            thread.uuid === data.conversationId &&
            !(
              thread.latestMessage &&
              new Date(thread.latestMessage.created) >= createdDate
            )
        ),
        thread => ({
          ...thread,
          latestMessage: data,
        })
      ),
      messages:
        existingMessageIndex > -1
          ? replaceAtIndex(state.messages, existingMessageIndex, {
              ...state.messages[existingMessageIndex],
              ...data,
            })
          : [...state.messages, data],
    };
  },
});

const onMessagesRead = socketReducer({
  key: 'Messages',
  onSend: (state, { payload: { conversationId, page } }) => {
    if (!page) {
      return {
        conversationId,
        messages: [],
      };
    }

    return {};
  },
  onReceive: (state, { data }) => {
    if (data.conversationId !== state.conversationId) {
      return {};
    }

    return {
      messages: data.messages.concat(state.messages).reduce((last, message) => {
        if (last.some(({ uuid }) => uuid === message.uuid)) {
          return last;
        }

        return [...last, message];
      }, []),
    };
  },
});

const onMessageMarkedRead = socketReducer({
  key: 'ReadMessage',
  onSend: (state, { payload }) => ({
    messages: replaceAtIndex(
      state.messages,
      state.messages.findIndex(({ uuid }) => uuid === payload.messageId),
      message => ({
        ...message,
        markedRead: true,
      })
    ),
  }),
  onReceive: (state, { data }) => ({
    messages: replaceAtIndex(
      state.messages,
      state.messages.findIndex(({ uuid }) => uuid === data.messageId),
      ({ markedRead, ...message }) => ({
        ...message,
        readBy: message.readBy
          .filter(({ id }) => id !== data.recipient.id)
          .concat([data.recipient]),
      })
    ),
    unreadCount: Math.max(0, state.unreadCount - 1),
  }),
});

const onUnreadCount = (state, { data: unreadCount }) => ({
  ...state,
  unreadCount,
});

const onUnreadCountQuotes = (state, { data: { unreadQuotes } }) => ({
  ...state,
  unreadQuotes,
});

const onTopMessages = socketReducer({
  key: 'TopMessages',
  onReceive: (state, { data }) => ({
    topMessages: data,
  }),
});

export default function conversationsReducer(state = initialState, action) {
  switch (action.type) {
    case CONVERSATION_CREATED:
      return onCreateConversation(state, action);
    case CONVERSATION_READ_ONE:
      return onReadOneConversation(state, action);
    case CONVERSATION_READ_ALL:
      return onAllInboxConversations(state, action);
    case CONVERSATION_READ_ALL_UNREAD:
      return onAllUnreadConversations(state, action);
    case CONVERSATION_READ_ALL_ARCHIVED:
      return onAllArchivedConversations(state, action);
    case CONVERSATION_READ_ALL_SUPPLIER_V2:
      return onAllSupplierConversations(state, action);
    case MESSAGE_CREATED_V1:
    case MESSAGE_CREATED:
      return onMessageCreated(state, action);
    case MESSAGES_READ:
      return onMessagesRead(state, action);
    case MESSAGE_MARKED_READ:
      return onMessageMarkedRead(state, action);
    case UNREAD_COUNT_RECEIVED:
      return onUnreadCount(state, action);
    case UNREAD_COUNT_RECEIVED_QUOTES:
      return onUnreadCountQuotes(state, action);
    case TOP_MESSAGES_READ:
      return onTopMessages(state, action);
    case RESET_MESSAGES:
      return initialState;
    default:
      return state;
  }
}
