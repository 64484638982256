import { useEffect, useState } from 'react';
import { ParsedQuery } from 'query-string';

import { COOKIE_CHOICE_MODIFIED_EVENT } from '../constants/cookie-consent';
import { useUser } from './useUser';
import { handleCookieConsentChange } from '../helpers/cookieHub/cookieHubConsent';

interface UseCookieHubConsentArgs {
  queries: ParsedQuery;
}

export function useCookieHubConsent({ queries }: UseCookieHubConsentArgs) {
  const user = useUser();
  const [hasGivenInitialConsent, setHasGivenInitialConsent] = useState(false);

  // Call cookie consent handler on mount
  useEffect(() => {
    handleCookieConsentChange({ queries, user });
    setHasGivenInitialConsent(window.cookiehub?.hasAnswered());
  }, [queries, user]);

  // Add and remove event listener for GTM event with handler
  useEffect(() => {
    const handler = () => {
      const isAnyCategoryDisabled = handleCookieConsentChange({
        queries,
        user,
      });

      // If any category is denied, reload the page to ensure Datadog etc reinitialises with correct consent
      if (hasGivenInitialConsent && isAnyCategoryDisabled) {
        window.location.reload();
      }

      setHasGivenInitialConsent(window.cookiehub?.hasAnswered());
    };

    window.addEventListener(COOKIE_CHOICE_MODIFIED_EVENT, handler);

    return () => {
      window.removeEventListener(COOKIE_CHOICE_MODIFIED_EVENT, handler);
    };
  }, [queries, hasGivenInitialConsent, user]);
}
