import {
  QUOTE_TEMPLATE_CREATED,
  QUOTE_TEMPLATES_READ,
  QUOTE_TEMPLATE_DELETED,
  QUOTE_TEMPLATE_UPDATED,
  QUOTE_TEMPLATE_ERRORED,
} from '../constants/action-types';

const initialState = {
  error: '',
  templates: [],
};

const onCreate = (state, payload) => ({
  error: '',
  templates: [...state.templates, payload],
});

const onRead = (state, payload) => ({
  error: '',
  templates: payload,
});

const onDelete = (state, payload) => ({
  error: '',
  templates: state.templates.filter(template => template.uuid !== payload),
});

function onUpdate(state, payload) {
  const { uuid } = payload;
  const index = state.templates.findIndex(template => template.uuid === uuid);

  return {
    error: '',
    templates: state.templates
      .slice(0, index)
      .concat([payload])
      .concat(state.templates.slice(index + 1)),
  };
}

function onError(state, payload) {
  return {
    ...state,
    error: payload,
  };
}

export default function quoteTemplateReducer(state = initialState, action) {
  switch (action.type) {
    case QUOTE_TEMPLATE_CREATED:
      return onCreate(state, action.payload);
    case QUOTE_TEMPLATES_READ:
      return onRead(state, action.payload);
    case QUOTE_TEMPLATE_DELETED:
      return onDelete(state, action.payload);
    case QUOTE_TEMPLATE_UPDATED:
      return onUpdate(state, action.payload);
    case QUOTE_TEMPLATE_ERRORED:
      return onError(state, action.payload);
    default:
      return state;
  }
}
