import { fork } from 'redux-saga/effects';

import ioSaga from './io';
import conversationsSaga from './conversations';
import calendarSaga from './calendar';
import { paymentsSaga } from '../ducks/payments';
import { reviewSaga } from '../ducks/reviews';
import bookingSaga from './booking';
import supplierReviewsSaga from './supplier-reviews';
import callingSaga from './calling';
import customerEventsSaga from './customer-events';
import eventRequestSaga from './event-requests';
import supplierQuotesSaga from './supplier-quotes';

export default function* rootSaga() {
  yield fork(ioSaga);
  yield fork(conversationsSaga);
  yield fork(calendarSaga);
  yield fork(paymentsSaga);
  yield fork(reviewSaga);
  yield fork(bookingSaga);
  yield fork(supplierReviewsSaga);
  yield fork(callingSaga);
  yield fork(customerEventsSaga);
  yield fork(eventRequestSaga);
  yield fork(supplierQuotesSaga);
}
