import { takeLatest, put } from 'redux-saga/effects';

import {
  ON_RESET_CALL_STATE,
  RESET_CALL_STATE,
} from '../constants/action-types';

function* onResetCallState() {
  yield put({ type: RESET_CALL_STATE });
}

export default function* callingSaga() {
  yield takeLatest(ON_RESET_CALL_STATE, onResetCallState);
}
