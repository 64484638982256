import { call } from 'redux-saga/effects';
import axios from 'axios';

import config from '../../app-config';
import { withAuthHeader } from '../httpMiddleware/authorization';

export function* request({ url = '', ...requestOptions } = {}, options = {}) {
  const { auth = true, throwErr = false } = options;

  try {
    let req = {
      ...requestOptions,
      url: `${config.baseApiUri}${url}`,
    };

    if (auth) {
      req = yield call(withAuthHeader, req);
    }

    const res = yield call(axios, req);

    return res.data;
  } catch (err) {
    if (throwErr) {
      throw err;
    }

    yield call([console, 'error'], `[request] ${err.message}`);

    return null;
  }
}
