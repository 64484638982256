import {
  CONVERSATION_CREATED,
  CONVERSATION_READ_ONE,
  CONVERSATION_READ_ALL,
  MESSAGE_CREATED,
  MESSAGES_READ,
  MESSAGE_MARKED_READ,
  UNREAD_COUNT_REQUESTED,
  TOP_MESSAGES_READ,
  RESET_MESSAGES,
  UNREAD_COUNT_REQUESTED_QUOTES,
  CONVERSATION_READ_ALL_UNREAD,
  CONVERSATION_READ_ALL_ARCHIVED,
  CONVERSATION_READ_ALL_SUPPLIER_V2,
} from '../constants/actions.realtime';
import { httpService } from '../../index';

export const uploadFile = (conversationId, file) => {
  const formData = new FormData();

  formData.append(file.name, file);

  return httpService.request({
    path: `/uploads/upload/conversation/${conversationId}`,
    method: 'POST',
    body: formData,
  });
};

export const getUrls = async (conversationId, attachments) => {
  const urls = await Promise.all(
    attachments.map(async file => {
      const data = await uploadFile(conversationId, file);
      return data.url;
    })
  );
  return urls;
};

export const conversationCreated = (
  bookingId = null,
  eventRequestId,
  supplierId
) => ({
  type: CONVERSATION_CREATED,
  payload: {
    bookingId,
    event_request_id: eventRequestId,
    supplier_id: supplierId,
  },
  __FROM_SOCKET__: false,
});

export const conversationRead = conversationId => ({
  type: CONVERSATION_READ_ONE,
  payload: { conversationId },
  __FROM_SOCKET__: false,
});
export const allInboxConversations = (page = 0, limit = 10) => ({
  type: CONVERSATION_READ_ALL,
  payload: {
    page,
    limit,
  },
  __FROM_SOCKET__: false,
});

export const allUnreadConversations = (page = 0, limit = 10) => ({
  type: CONVERSATION_READ_ALL_UNREAD,
  payload: {
    page,
    limit,
  },
  __FROM_SOCKET__: false,
});

export const allArchivedConversations = (page = 0, limit = 10) => ({
  type: CONVERSATION_READ_ALL_ARCHIVED,
  payload: {
    page,
    limit,
  },
  __FROM_SOCKET__: false,
});

export const allConversationsReadSuppliers = (page = 0, limit = 10) => ({
  type: CONVERSATION_READ_ALL_SUPPLIER_V2,
  payload: {
    page,
    limit,
  },
  __FROM_SOCKET__: false,
});

export const messageSent = (
  conversationId,
  content,
  isSupplier,
  supplierName,
  urls
) => {
  const action = {
    type: MESSAGE_CREATED,
    payload: {
      conversationId,
      content,
    },
    __FROM_SOCKET__: false,
  };

  if (urls?.length) {
    action.payload.type = 'attachment';
    action.payload.content = JSON.stringify({
      attachments: urls,
      text: content,
    });
  }

  return action;
};

export const priceUpdateSent = (
  conversationId,
  covers,
  total,
  content = ''
) => ({
  type: MESSAGE_CREATED,
  payload: {
    conversationId,
    type: 'newPrice',
    covers,
    total,
    content,
  },
  __FROM_SOCKET__: false,
});

export const threadRead = (conversationId, page = 0, limit = 10) => ({
  type: MESSAGES_READ,
  payload: {
    conversationId,
    page,
    limit,
  },
  __FROM_SOCKET__: false,
});

export const messageRead = messageId => ({
  type: MESSAGE_MARKED_READ,
  payload: { messageId },
  __FROM_SOCKET__: false,
});

export const unreadCountRequested = () => ({
  type: UNREAD_COUNT_REQUESTED,
  __FROM_SOCKET__: false,
});

export const unreadQuoteMessagesRequested = quoteIds => ({
  type: UNREAD_COUNT_REQUESTED_QUOTES,
  payload: quoteIds,
  __FROM_SOCKET__: false,
});

export const topMessagesRead = () => ({
  type: TOP_MESSAGES_READ,
  __FROM_SOCKET__: false,
});

export const resetMessages = () => ({
  type: RESET_MESSAGES,
  __FROM_SOCKET__: false,
});
