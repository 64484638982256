import { eventChannel } from 'redux-saga';
import {
  actionChannel,
  flush,
  take,
  fork,
  cancel,
  call,
  put,
} from 'redux-saga/effects';
import io from 'socket.io-client';
import cookies from 'js-cookie';
import { USER_ID } from '../constants-togather/auth';
import { isCognitoAuthentication } from '../helpers/auth/cognito';
import * as actions from '../constants/actions.realtime';
import { SOCKET_READY } from '../constants/action-types';
import config from '../../app-config';
import { getToken } from '../httpMiddleware/authorization';

import { onLoginChange } from './login';

const realTimeActions = Object.values(actions);

function makeIOChannel(socket) {
  return eventChannel((emit) => {
    socket.on('reconnect', () => {
      socket.emit('subscribe');
    });

    realTimeActions.forEach((event) => {
      // Listen for all real time action types from the socket,
      // and dispatch them, marking them as *from* the socket
      socket.on(event, (data) => {
        return emit({
          type: event,
          data,
          __FROM_SOCKET__: true,
        });
      });
    });

    return () => {
      socket.disconnect();
    };
  });
}

function* socketListener(socket) {
  const channel = yield call(makeIOChannel, socket);

  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* sendEventsToSocket(socket, localChannel) {
  yield take(SOCKET_READY);

  while (true) {
    const action = yield take(localChannel);

    const { type, __FROM_SOCKET__, ...data } = action;

    if (__FROM_SOCKET__ === false) {
      yield fork([socket, 'emit'], type, data);
    }
  }
}

function* socketEmitter(socket, localChannel) {
  yield call([socket, 'emit'], 'subscribe');

  yield fork(sendEventsToSocket, socket, localChannel);
}

function* createSocket(localChannel) {
  const token = yield call(getToken);
  let socketShoudReconnect = true;
  let connectionRetryCount = 0;
  const isCognitoUser = token && isCognitoAuthentication(token);

  const socket = yield call(io, config.baseApiUri, {
    query: {
      token,
      ...(isCognitoUser && {
        'x-new-auth': true,
        'x-user-id': cookies.get(USER_ID),
      }),
    },
  });
  socket.reconnectionDelay = 1000;

  yield fork(socketListener, socket);
  yield fork(socketEmitter, socket, localChannel);
  yield put({ type: SOCKET_READY });

  socket.on('UNAUTHORIZED', () => {
    connectionRetryCount += 1;
    if (connectionRetryCount > 5) {
      socketShoudReconnect = false;
    }
  });

  socket.on('connect', () => {
    connectionRetryCount = 0;
  });

  socket.on('disconnect', async (reason) => {
    if (socketShoudReconnect && reason === 'io server disconnect') {
      const newToken = await getToken();
      socket.query.token = newToken;
      socket.connect();
    }
  });
}

export default function* ioSaga() {
  let task = null;
  // Listen for all real time action types from redux,
  // and emit them *only if* they are marked explicitly as
  // *to* the socket
  const localChannel = yield actionChannel(realTimeActions);

  while (true) {
    const loggedIn = yield call(onLoginChange);

    if (task) {
      yield cancel(task);
    }
    if (loggedIn) {
      task = yield fork(createSocket, localChannel);
    } else {
      yield flush(localChannel);
    }
  }
}
