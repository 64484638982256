import { CALL_STATE_UPDATE } from '../constants/actions.realtime';

import { RESET_CALL_STATE, CALL_STARTED } from '../constants/action-types';

const initialState = {
  state: '',
};

export default function callingReducer(state = initialState, action) {
  switch (action.type) {
    case CALL_STATE_UPDATE:
    case CALL_STARTED:
      return { state: action.data.state };
    case RESET_CALL_STATE:
      return { state: '' };
    default:
      return state;
  }
}
