import { LOCATION_CHANGE } from 'react-router-redux';
import { TOGGLE_MODAL } from '../constants/action-types';

const initialState = {
  isVisible: false,
  children: undefined,
  showButton: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        ...action.payload,
        isVisible: !state.isVisible,
      };
    case LOCATION_CHANGE:
      return { ...state, isVisible: false };
    default:
      return state;
  }
}
