import {
  SET_HEADER_COLOR,
} from '../constants/action-types';

const initialState = {
  color: 'white',
  isSticky: true,
  isHidden: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_HEADER_COLOR:
      return {
        ...state,
        color: action.payload,
      };
    default:
      return state;
  }
}
