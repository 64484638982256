import { takeLatest, call, put } from 'redux-saga/effects';
import axios from 'axios';

const api = 'https://api.reviews.co.uk/merchant/reviews?store=feast-it-com';

export const initialState = {
  loading: false,
  error: false,
  reviews: null,
};

export const REQUESTED = 'reviews/REQUESTED';
const RECEIVED = 'reviews/RECEIVED';

export const requestReviews = () => ({
  type: REQUESTED,
});

function* onRequestCompanyReviews() {
  try {
    const { data } = yield call(axios.get, api);
    yield put({ type: RECEIVED, stats: data.stats });
  } catch {
    //
  }
}

export function* reviewSaga() {
  yield takeLatest(REQUESTED, onRequestCompanyReviews);
}

export default function reviews(state = initialState, action) {
  switch (action.type) {
    case REQUESTED:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case RECEIVED: {
      const { stats } = action;

      return {
        loading: false,
        error: !stats,
        reviews: stats
          ? {
              value: stats.average_rating,
              count: stats.total_reviews,
            }
          : null,
      };
    }

    default:
      return state;
  }
}
