import { takeLatest, call, put } from 'redux-saga/effects';

import { request } from './api';
import { withErrorHandling } from './error-handling';
import {
  paginatedlistReceived,
  received,
  errored,
} from '../actions/supplier-reviews';
import { toggleNotification } from '../actions/notifications';
import { history } from '../modules/history';

import {
  SUPPLIER_PAGINATED_REVIEW_LIST_REQUESTED,
  SUPPLIER_REVIEW_CREATED,
  SUPPLIER_REVIEW_READ,
  SUPPLIER_REVIEW_UPDATED,
  SUPPLIER_REVIEW_AUTHENTICATED,
} from '../constants/action-types';

import paths from '../constants/paths';

const onRequestPaginatedList = withErrorHandling(function* requestList({
  payload,
}) {
  const { supplierId, pageNumber, limit } = payload;
  const items = yield call(
    request,
    {
      url: `/reviews/supplier/${supplierId}/public/${pageNumber}?limit=${limit}`,
    },
    {
      throwErr: true,
    }
  );
  yield put(paginatedlistReceived(items));
},
errored);

const onCreate = withErrorHandling(function* create({ data }) {
  const isBooking = data.source === 'booking';
  const notificationText = isBooking
    ? 'Thanks for submitting your review!'
    : 'Thanks! Your review has been submitted for verification';

  const review = yield call(
    request,
    {
      method: 'POST',
      url: '/reviews',
      data,
    },
    {
      throwErr: true,
    }
  );

  yield put(received(review));
  yield put(
    toggleNotification({
      text: notificationText,
      isVisible: true,
      shouldDismiss: true,
      color: 'green',
    })
  );
  if (isBooking) {
    yield call(history.push, paths.BOOKINGS);
  }
}, errored);

const onRead = withErrorHandling(function* read({ uuid, token }) {
  const review = yield call(
    request,
    {
      url: `/reviews/${uuid}?token=${token}`,
    },
    { throwErr: true }
  );

  yield put(received(review, { single: true }));
}, errored);

const onUpdate = withErrorHandling(function* update({
  uuid,
  data,
  token,
  metadata,
}) {
  const { uuid: discard, ...rest } = data;

  const review = yield call(
    request,
    {
      method: 'PATCH',
      url: `/reviews/${uuid}`,
      params: token ? { token } : {},
      data: rest,
    },
    {
      throwErr: true,
    }
  );

  yield put(received(review, { ...metadata, uuid, updated: true }));
},
errored);

const onAuthenticate = withErrorHandling(function* authenticate({
  uuid,
  token,
}) {
  const review = yield call(
    request,
    {
      method: 'PATCH',
      url: `/reviews/${uuid}?token=${token}`,
      data: {
        authenticated: true,
        published: true,
      },
    },
    {
      throwErr: true,
      auth: false,
    }
  );

  yield put(received(review, { uuid, token }));
},
errored);

export default function* supplierReviewsSaga() {
  yield takeLatest(
    SUPPLIER_PAGINATED_REVIEW_LIST_REQUESTED,
    onRequestPaginatedList
  );
  yield takeLatest(SUPPLIER_REVIEW_CREATED, onCreate);
  yield takeLatest(SUPPLIER_REVIEW_READ, onRead);
  yield takeLatest(SUPPLIER_REVIEW_UPDATED, onUpdate);
  yield takeLatest(SUPPLIER_REVIEW_AUTHENTICATED, onAuthenticate);
}
