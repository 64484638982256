import {
  TOGGLE_NOTIFICATION,
  RESET_NOTIFICATION,
} from '../constants/action-types';

const initialState = {
  isVisible: false,
  text: null,
  color: null,
  isDismissible: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_NOTIFICATION:
      return action.payload;
    case RESET_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
};
