import React from 'react';
import mixpanel from 'mixpanel-browser';
import PropTypes from 'prop-types';

import appConfig from '../app-config';
import { App as AppTogather } from './app-togather';

mixpanel.init(appConfig.mixpanelProjectToken, {
  opt_out_tracking_by_default: true,
});

export default function App({ store }) {
  return <AppTogather store={store} />;
}

App.propTypes = {
  store: PropTypes.shape({}).isRequired,
};
