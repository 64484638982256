import { takeLatest, call, put } from 'redux-saga/effects';

import { history } from '../modules/history';
import { request } from './api';

import { toggleNotification } from '../actions/notifications';
import { CREATE_SUPPLIER_QUOTE } from '../constants/action-types';
import paths from '../constants/paths';

function* createQuote({ requestId, data }) {
  yield call(request, {
    url: `/event-requests/${requestId}/quotes`,
    method: 'POST',
    data,
  });

  yield put(
    toggleNotification({
      text: 'Thanks, your quote has been sent',
      isVisible: true,
      shouldDismiss: true,
      color: 'green',
    })
  );

  yield call(history.push, paths.SUPPLIER_EVENTS);
}

export default function* supplierQuotesSaga() {
  yield takeLatest(CREATE_SUPPLIER_QUOTE, createQuote);
}
