import { unionBy } from '../libs/helpers';

import {
  FETCH_DYNAMIC_LANDINGS,
  FETCH_DYNAMIC_LANDING,
  FETCH_SITEMAP,
} from '../constants/action-types';

const initialState = {
  pages: {
    results: [],
    isLoading: true,
  },
  sitemap: {
    isLoading: true,
  },
};

// Selectors
export const getFeaturedLandings = state =>
  state.results.filter(landing => landing.data.featured === 'yes');

export const getLanding = (state, slug) => {
  const currentDynamicLanding = state.results.filter(
    landing => landing.uid === slug
  );
  return currentDynamicLanding[0];
};

// Reducer
function dynamicLandings(state = initialState, action) {
  switch (action.type) {
    case FETCH_DYNAMIC_LANDINGS:
      return {
        ...state,
        pages: {
          ...action.payload,
          results: unionBy(state.pages.results, action.payload.results, 'id'),
          isLoading: false,
        },
      };
    case FETCH_DYNAMIC_LANDING:
      return {
        ...state,
        pages: {
          ...state.pages,
          results: unionBy(state.pages.results, action.payload.results, 'id'),
          isLoading: false,
        },
      };
    case FETCH_SITEMAP:
      return {
        ...state,
        sitemap: {
          ...action.payload.results[0].data,
          isLoading: false,
        },
      };
    default:
      return state;
  }
}

export default dynamicLandings;
