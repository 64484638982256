import {
  FETCH_EVENT_REQUESTS,
  RESET_EVENT_REQUESTS,
  RESET,
} from '../constants/action-types';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_EVENT_REQUESTS:
      return action.payload;
    case RESET_EVENT_REQUESTS:
    case RESET:
      return [];
    default:
      return state;
  }
}
