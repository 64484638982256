export const ADMIN_ROLE = {
  name: 'admin',
  level: 3,
};

export const VENDOR_ROLE = {
  name: 'supplier',
  level: 2,
};

export const CUSTOMER_ROLE = {
  name: 'customer',
  level: 1,
};

export const VISITOR_ROLE = {
  name: 'visitor',
  level: 0,
};
