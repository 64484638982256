import { actionTypes } from 'redux-form';

import {
  SET_EVENT,
  RESET_EVENT,
  SET_EVENT_TOTAL,
  SET_EVENT_BOOKING_ID,
  SET_PERCENTAGE_VALUE,
} from '../constants/action-types';

const initialState = {
  date: null,
  postcode: '',
  vendorId: undefined,
  isDateValid: false,
  isPostcodeValid: false,
  guests: undefined,
  dietaryItems: [],
  total: 0,
  id: '',
  bookingId: '',
  percentageValue: null,
  menuPrice: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_EVENT:
      return initialState;
    case SET_EVENT_TOTAL:
      return { ...state, total: action.payload };
    case SET_EVENT_BOOKING_ID:
      return { ...state, bookingId: action.payload };
    case SET_EVENT:
      return { ...state, ...action.payload };
    case actionTypes.CHANGE:
      if (action.meta.field === 'guests' && action.meta.form === 'about') {
        return { ...state, guests: action.payload };
      }
      return state;
    case SET_PERCENTAGE_VALUE:
      return { ...state, percentageValue: action.payload };
    default:
      return state;
  }
}
