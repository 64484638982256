const isPlainObject = object =>
  Object.prototype.toString.call(object) === '[object Object]';

function normalizeEmptyStrings(object, replacer) {
  // if this is an object of some kind then iterate
  // its properties and return an object without empty strings
  if (isPlainObject(object)) {
    return Object.keys(object).reduce((copy, key) => {
      let value = object[key];

      if (value === '') {
        value = replacer;
      }

      copy[key] = normalizeEmptyStrings(value, replacer);
      return copy;
    }, {});
  }

  if (Array.isArray(object)) {
    return object.map(item => normalizeEmptyStrings(item, replacer));
  }

  return object;
}

function withFilteredBody(request) {
  const replacer = request.method === 'POST' ? undefined : null;

  const body = JSON.stringify(normalizeEmptyStrings(request.body, replacer));

  return { ...request, body };
}

export default async function jsonBody(request, next) {
  if (!isPlainObject(request.body)) {
    return await next(request);
  }

  const filteredBodyRequest = withFilteredBody(request);
  const headers = { ...request.headers, 'Content-Type': 'application/json' };

  return await next({ ...filteredBodyRequest, headers });
}
