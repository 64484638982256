import { SET_METADATA, RESET_METADATA } from '../constants/action-types';

const initialState = () => ({
  title: 'Togather',
  subTitle: 'Book Unique Street Food Caterers',
  description:
    'Discover, compare and easily book high quality street food caterers for your event.',
  image: `${window.location.origin}/open-graph.png`,
  type: 'website',
  url: `${window.location.origin}${window.location.pathname}`,
});

export default function (state = initialState(), action) {
  switch (action.type) {
    case RESET_METADATA:
      return { ...state, ...initialState() };
    case SET_METADATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
