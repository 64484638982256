import {
  FETCH_SUPPLIER,
  NOT_FOUND_SUPPLIER,
  RESET,
} from '../constants/action-types';
import { sortBy } from '../libs/helpers';

const initialState = {
  public: {
    description: '',
    images: [],
    basePrice: 0,
    logistics: {
      availableHours: {},
      setup: {
        details: '',
      },
    },
  },
  external: {},
  recommendations: [],
  sample: [],
  dietary: [],
  cuisine: [],
  tags: [],
  types: [],
  isLoading: true,
  isPresent: true,
};

const sortSample = sample =>
  sortBy(
    (sample || []).map(group => ({
      ...group,
      items: sortBy(group.items, 'order'),
    })),
    'order'
  );

function vendor(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUPPLIER:
      return {
        ...action.payload,
        sample: sortSample(action.payload.sample),
        isLoading: false,
        isPresent: true,
      };
    case NOT_FOUND_SUPPLIER:
      return { ...initialState, isPresent: false };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

export default vendor;
