import {
  call,
  cancel,
  debounce,
  fork,
  put,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { api } from '../modules/api';
import { history } from '../modules/history';

import { request } from './api';
import { onLoginChange } from './login';
import { unreadCountRequested } from '../actions/conversations';
import {
  MESSAGE_CREATED,
  MESSAGE_CREATED_V1,
  MESSAGE_MARKED_READ,
  MESSAGES_READ,
} from '../constants/actions.realtime';
import {
  BOOKING_CONVERSATION_REDIRECTED,
  SOCKET_READY,
} from '../constants/action-types';
import { CHAT_TO_SUPPLIER } from '../constants/analytics';
import { trackEvent } from '../modules/analytics/analytics';

function* refreshUnreadCount(action = null) {
  if (action && !action.__FROM_SOCKET__) {
    return;
  }
  yield put(unreadCountRequested());
}

function* getUnreadCount() {
  yield take(SOCKET_READY);
  yield call(refreshUnreadCount);
  yield debounce(
    500,
    [MESSAGE_CREATED, MESSAGE_CREATED_V1, MESSAGES_READ, MESSAGE_MARKED_READ],
    refreshUnreadCount
  );
}

function* getUnreadCountLoop() {
  let task = null;

  while (true) {
    const loggedIn = yield call(onLoginChange);
    if (task) {
      yield cancel(task);
    }
    if (loggedIn) {
      task = yield fork(getUnreadCount);
    }
  }
}

export function* redirectBookingToConversation({ bookingId }) {
  const conversation = yield call(request, {
    url: `/bookings-v2/${bookingId}/getConversation`,
  });

  if (conversation) {
    yield call(history.push, `/messages/${conversation.uuid}`);
  }
}

export default function* conversationsSaga() {
  yield fork(getUnreadCountLoop);
  yield takeEvery(
    BOOKING_CONVERSATION_REDIRECTED,
    redirectBookingToConversation
  );
}

export const goToQuoteConversation = async (quoteId, location) => {
  const conversation = await api.get(`/bookings-v2/${quoteId}/getConversation`);

  if (conversation?.data?.uuid) {
    trackEvent(CHAT_TO_SUPPLIER, { Location: location });
    history.push(`/messages/${conversation.data.uuid}`);
  }
};
