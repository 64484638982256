import { FETCH_SUPPLIER_REQUESTS } from '../constants/action-types';

const initialState = {
  items: [],
  numPages: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUPPLIER_REQUESTS:
      return {
        ...state,
        items: action.payload.results,
        numPages: action.payload.numPages,
        totalRecords: action.payload.totalRecords,
      };
    default:
      return state;
  }
}
