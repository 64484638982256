import {
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_REQUEST_ROBEX,
  FETCH_EVENT_REQUEST_START,
  FETCH_EVENT_REQUEST_UNREAD_MESSAGES,
  FETCH_EVENT_REQUEST_ERROR,
  RESET,
} from '../constants/action-types';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_EVENT_REQUEST_START:
      return {};
    case FETCH_EVENT_REQUEST:
      return { ...state, ...action.payload };
    case FETCH_EVENT_REQUEST_ROBEX:
      return {
        ...state,
        robex: action.payload,
      };
    case FETCH_EVENT_REQUEST_UNREAD_MESSAGES:
      return {
        ...state,
        unreadQuotes: action.payload,
      };
    case FETCH_EVENT_REQUEST_ERROR:
      return {
        ...state,
        error: action.payload.status,
      };
    case RESET:
      return {};
    default:
      return state;
  }
}
