import { call, put } from 'redux-saga/effects';

export function withErrorHandling(saga, onError) {
  return function* handledSaga(action) {
    try {
      yield call(saga, action);
    } catch (err) {
      const message =
        err.response && err.response.data
          ? err.response.data.message
          : err.message;

      yield put(onError(message));
    }
  };
}
