// state
export const initialState = {
  url: null,
  text: null,
};

// actions
const BREADCRUMB_SET = 'BREADCRUMB_SET';

export const breadcrumbSet = (url, text) => ({
  type: BREADCRUMB_SET,
  url,
  text,
});

// reducers
export default function breadcrumbs(state = initialState, action) {
  if (action && action.type === BREADCRUMB_SET) {
    const { url, text } = action;

    if (!url) {
      return initialState;
    }

    return { url, text };
  }

  return state;
}
