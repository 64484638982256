import { EventTypes } from 'redux-segment';

import {
  FETCH_BOOKING,
  BOOKING_PRICE_UPDATED,
  BOOKING_CONVERSATION_REDIRECTED,
  UPDATE_BOOKING,
  FETCH_QUOTE_ID,
} from '../constants/action-types';

import { getQuoteAmount } from '../libs/finance-utils';
import { getSupplier } from './vendors';
import { httpService } from '../../index';
import { PROCEEDED_TO_PAYMENT } from '../constants/analytics';
import { captureError } from '../modules/monitoring';

export const fetchBookings = () =>
  httpService.request({
    path: '/bookings-v2',
  });

export const fetchSupplierBookings = () =>
  httpService.request({ path: '/bookings-v2/supplier/booked' });

export function fetchBooking(id) {
  return httpService.request({
    path: `/bookings-v2/${id}`,
  });
}

export const getBookingWithPayments = id => async dispatch => {
  const booking = await httpService.request({
    path: `/bookings-v2/${id}`,
  });

  return dispatch({ type: FETCH_BOOKING, payload: booking });
};

export const bookingPriceUpdated = (bookingId, total) => ({
  type: BOOKING_PRICE_UPDATED,
  bookingId,
  total,
});

export const bookingRedirectedToConversation = (bookingId, booking = null) => ({
  type: BOOKING_CONVERSATION_REDIRECTED,
  bookingId,
  booking,
});

export const updateBooking = payload => ({
  type: UPDATE_BOOKING,
  payload,
});

// Analytics event
export const proceedToPayment = (booking, supplier) => dispatch =>
  dispatch({
    type: PROCEEDED_TO_PAYMENT,
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: PROCEEDED_TO_PAYMENT,
          properties: {
            Cuisine: supplier?.cuisine?.map(c => c.name),
            'Quote Amount': getQuoteAmount(booking),
            Setup: supplier?.types?.map(v => v.name),
            Supplier: supplier?.name,
            'Supplier Type': supplier?.types?.map(
              supplierType => supplierType?.name
            ),
          },
        },
      },
    },
  });

export const getBookingAndSupplier = id => async dispatch => {
  const quote = await httpService.request({
    path: `/bookings-v2/${id}`,
  });

  dispatch({ type: FETCH_BOOKING, payload: quote });
  dispatch(getSupplier(quote.supplier_id));
};

export const getBookingConversation = async quoteId =>
  httpService.request({
    path: `/bookings-v2/${quoteId}/getConversation`,
  });

export const getQuoteIdFromBookingId = bookingId => dispatch =>
  httpService
    .request({
      path: `/quotes/get-quote/${bookingId}`,
    })
    .then(payload => {
      dispatch({ type: FETCH_QUOTE_ID, payload });
    })
    .catch(err => {
      captureError(err);
    });
