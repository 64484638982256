import cookies from 'js-cookie';
import {
  getLatestCognitoToken,
  isCognitoAuthentication,
  isImpersonationSession,
} from '../helpers/auth/cognito';
import { AUTH_TOKEN, USER_ID } from '../constants-togather/auth';

export async function getToken(request = { token: null }) {
  const token = cookies.get(AUTH_TOKEN) || request.token;

  if (token && isCognitoAuthentication(token) && !isImpersonationSession()) {
    return getLatestCognitoToken();
  }
  return request.token ?? token;
}

export async function withAuthHeader(request = {}) {
  const token = await getToken(request);
  const userId = cookies.get(USER_ID);

  if (!(token && typeof token === 'string')) {
    return request;
  }

  if (token && isCognitoAuthentication(token)) {
    return {
      ...request,
      headers: {
        ...(request.headers || {}),
        authorization: `Bearer ${token}`,
        'x-new-auth': true,
        'x-user-id': userId,
        'x-impersonated-user-id': isImpersonationSession() ? userId : null,
      },
    };
  }

  const authorization = `Bearer ${token}`;
  const headers = { ...(request.headers || {}), authorization };

  return { ...request, headers };
}

export const createAuthorization = () => async (request, next) =>
  await next(await withAuthHeader(request));
