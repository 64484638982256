import { FETCH_EVENT_CROSS_SELL, RESET } from '../constants/action-types';

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_EVENT_CROSS_SELL:
      return action.payload;
    case RESET:
      return [];
    default:
      return state;
  }
}
