// Actions which are sent to/from the socket.io connection

export const CONVERSATION_CREATED = 'io/conversations/CREATED_V2';
export const CONVERSATION_READ_ONE = 'io/conversations/READ_ONE_V2';
export const MESSAGE_CREATED = 'io/conversations/MESSAGE_CREATED_V2';
export const MESSAGE_CREATED_V1 = 'io/conversations/MESSAGE_CREATED';

export const CONVERSATION_READ_ALL = 'io/conversations/READ_ALL_V2';

export const CONVERSATION_READ_ALL_ARCHIVED =
  'io/conversations/READ_ALL_ARCHIVED';

export const CONVERSATION_READ_ALL_UNREAD = 'io/conversations/READ_ALL_UNREAD';

export const CONVERSATION_READ_ALL_SUPPLIER_V2 =
  'io/conversations/READ_ALL_SUPPLIER_V2';

export const MESSAGES_READ = 'io/conversations/MESSAGES_READ';
export const MESSAGE_MARKED_READ = 'io/conversations/MESSAGE_MARKED_READ';

export const UNREAD_COUNT_REQUESTED = 'io/conversations/UNREAD_COUNT_REQUESTED';
export const UNREAD_COUNT_RECEIVED = 'io/conversations/UNREAD_COUNT_RECEIVED';
export const UNREAD_COUNT_REQUESTED_QUOTES =
  'io/conversations/UNREAD_COUNT_REQUESTED_QUOTES';
export const UNREAD_COUNT_RECEIVED_QUOTES =
  'io/conversations/UNREAD_COUNT_RECEIVED_QUOTES';

export const TOP_MESSAGES_READ = 'io/conversations/TOP_MESSAGES_READ';
export const RESET_MESSAGES = 'io/conversations/RESET_MESSAGES';

export const CALL_STATE_UPDATE = 'io/conversations/CALL_STATE_UPDATE';
