const RESET = 'bookingProtection/RESET';
const UPDATE = 'bookingProtection/UPDATE';

export const resetBookingProtection = () => ({
  type: RESET,
});

export const updateBookingProtection = protectionType => ({
  type: UPDATE,
  protectionType,
});

export const initialState = {
  type: null,
};

export default function bookingProtectionReducer(state = initialState, action) {
  switch (action.type) {
    case RESET:
      return initialState;

    case UPDATE:
      return {
        type: action.protectionType,
      };

    default:
      return state;
  }
}
