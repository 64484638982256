import { takeLatest, call, put } from 'redux-saga/effects';
import { request } from './api';

import {
  FETCH_CUSTOMER_EVENTS,
  GET_ALL_CUSTOMER_EVENTS,
  FETCH_CUSTOMER_EVENT,
  GET_CUSTOMER_EVENT,
  FETCH_EVENT_CROSS_SELL,
  GET_EVENT_CROSS_SELL,
} from '../constants/action-types';

function* fetchEvents({ userId }) {
  const events = yield call(request, {
    url: `/events/${userId}/user`,
    method: 'GET',
  });
  yield put({
    type: FETCH_CUSTOMER_EVENTS,
    payload: events,
  });
}

function* fetchEvent({ eventId }) {
  const event = yield call(request, {
    url: `/events/${eventId}`,
    method: 'GET',
  });
  yield put({
    type: FETCH_CUSTOMER_EVENT,
    payload: event,
  });
}

function* fetchEventCrossSell({ eventId }) {
  const crossSell = yield call(request, {
    url: `/events/${eventId}/cross-sell`,
    method: 'GET',
  });
  yield put({
    type: FETCH_EVENT_CROSS_SELL,
    payload: crossSell,
  });
}

export default function* customerEventsSaga() {
  yield takeLatest(GET_ALL_CUSTOMER_EVENTS, fetchEvents);
  yield takeLatest(GET_CUSTOMER_EVENT, fetchEvent);
  yield takeLatest(GET_EVENT_CROSS_SELL, fetchEventCrossSell);
}
