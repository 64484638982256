import { createSelector } from '@reduxjs/toolkit';

export function getUserSupplier(state) {
  if (state.user.suppliers && state.user.suppliers.length) {
    return state.user.suppliers[0];
  }

  return null;
}

export const getUserSupplierId = createSelector(
  getUserSupplier,
  supplier => supplier && supplier.uuid
);

export const getSuppliers = state => state.vendors.items;
