import {
  FETCH_OPEN_EVENTS,
  FETCH_TOTAL_OPEN_EVENTS,
} from '../constants/action-types';

const initialState = {
  items: [],
  numPages: 0,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_OPEN_EVENTS:
      return {
        ...state,
        items: action.payload.results,
        numPages: action.payload.numPages,
        totalRecords: action.payload.totalRecords,
      };
    case FETCH_TOTAL_OPEN_EVENTS:
      return { ...state, total: action.payload.totalRecords };
    default:
      return state;
  }
}
