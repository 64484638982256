import jwtDecode from 'jwt-decode';
import { fromUnixTime, isPast } from 'date-fns';
import { fetchAuthSession } from 'aws-amplify/src/auth';
import cookies from 'js-cookie';
import {
  ADMIN_GROUP_TYPE,
  COGNITO_IDENTITY_FIELDS,
  CUSTOMER_GROUP_TYPE,
  GOOGLE_SSO_USERNAME_PREFIX,
  SUPPLIER_GROUP_TYPE,
} from '../../constants-togather/cognito';
import {
  AUTH_TOKEN,
  COGNITO_SSO_LOGOUT,
  IS_IMPERSONATION_SESSION,
} from '../../constants-togather/auth';

export const isCognitoAuthentication = (token: string): boolean => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);

  return Array.isArray(decoded[COGNITO_IDENTITY_FIELDS.GROUPS]);
};

export const isGoogleSSOUser = (): boolean => {
  const authenticationToken = cookies.get(AUTH_TOKEN);

  if (!authenticationToken) {
    return false;
  }
  const decoded = jwtDecode(authenticationToken);

  return (
    Array.isArray(decoded[COGNITO_IDENTITY_FIELDS.GROUPS]) &&
    !!decoded[COGNITO_IDENTITY_FIELDS.USERNAME]?.startsWith(
      GOOGLE_SSO_USERNAME_PREFIX
    )
  );
};

export const isImpersonationSession = () => {
  const authenticationToken = cookies.get(AUTH_TOKEN);

  if (!authenticationToken) {
    return false;
  }

  if (isCognitoAuthentication(authenticationToken)) {
    return Boolean(cookies.get(IS_IMPERSONATION_SESSION));
  }

  const { impersonationSession }: { impersonationSession: boolean } = jwtDecode(
    authenticationToken
  );

  return impersonationSession ?? false;
};

export const isNewlyCreatedUser = (
  idToken: Record<string, unknown>
): boolean => {
  if (!idToken) {
    return false;
  }

  return Boolean(!idToken[COGNITO_IDENTITY_FIELDS.DATABASE_ID]);
};

export const getLatestCognitoToken = async (): Promise<string | undefined> => {
  if (isImpersonationSession()) {
    return cookies.get(AUTH_TOKEN);
  }

  let session = await fetchAuthSession();

  if (!session?.tokens?.accessToken) {
    return undefined;
  }

  if (isPast(fromUnixTime(session.tokens.accessToken.payload.exp))) {
    session = await fetchAuthSession({
      forceRefresh: true,
    });
  }

  return session.tokens?.accessToken?.toString();
};

export const isCognitoUserCustomer = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);

  return (
    decoded[COGNITO_IDENTITY_FIELDS.GROUPS]?.includes(CUSTOMER_GROUP_TYPE) ||
    decoded[COGNITO_IDENTITY_FIELDS.GROUPS]?.includes(ADMIN_GROUP_TYPE)
  );
};

export const isCognitoUserSupplier = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);

  return decoded[COGNITO_IDENTITY_FIELDS.GROUPS]?.includes(SUPPLIER_GROUP_TYPE);
};

export const removeSSOLogoutFlag = (): void => {
  localStorage.removeItem(COGNITO_SSO_LOGOUT);
};

export const isSSOLogoutFlagSet = (): boolean => {
  return !!localStorage.getItem(COGNITO_SSO_LOGOUT);
};
