import { groupBy } from '../libs/helpers';

import { FETCH_TAGS, RECEIVED_TAGS } from '../constants/action-types';

const initialState = {
  isLoading: 0,
};

function vendors(state = initialState, action) {
  switch (action.type) {
    case FETCH_TAGS:
      return { ...state, isLoading: state.isLoading + 1 };
    case RECEIVED_TAGS:
      return {
        ...state,
        ...groupBy(action.payload, tag => tag.pid),
        isLoading: state.isLoading - 1,
      };
    default:
      return state;
  }
}

export default vendors;
