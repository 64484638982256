export default function stringToBase64(string) {
  return window.btoa(unescape(encodeURIComponent(string)));
}

export function createUrlParams(obj) {
  const urlSearchParams = Object.keys(obj)
    .reduce((acc, curr, i) => {
      if (Array.isArray(obj[curr])) {
        return obj[curr].reduce(
          (_acc, _curr, j) =>
            `${_acc}${i > 0 || j > 0 ? '&' : ''}${curr}[]=${_curr}`,
          acc
        );
      }
      return obj[curr] && obj[curr].toString().length
        ? `${acc}${i > 0 ? '&' : ''}${curr}=${obj[curr]}`
        : acc;
    }, '?')
    .replace('+', '%2B');
  return urlSearchParams;
}

const bracketsRegEx = /\[\]+$/g; // []

export function sanitizeQuery(query) {
  return Object.keys(query).reduce((acc, curr) => {
    if (curr.match(bracketsRegEx)) {
      return {
        ...acc,
        [curr.replace(bracketsRegEx, '')]: Array.isArray(query[curr])
          ? query[curr]
          : [query[curr]],
      };
    }

    return { ...acc, [curr]: query[curr] };
  }, {});
}

export function updateUrlParams(location, newParams) {
  const { pathname, query } = location;

  return `${pathname}${createUrlParams({
    ...sanitizeQuery(query),
    ...newParams,
  })}`;
}
