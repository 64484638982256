import { fork, takeLatest, call, put } from 'redux-saga/effects';

import { QUOTE_BOOKED_NOW } from '../constants/action-types';
import { trackChatToSupplierEvent } from '../libs/analytics';
import { request } from './api';
import { proceedToPayment } from '../actions/bookings';
import { breadcrumbSet } from '../ducks/breadcrumbs';
import { redirectBookingToConversation } from './conversations';
import { history } from '../modules/history';

function* trackProceedToBooking(bookingId, supplier) {
  const booking = yield call(request, {
    url: `/booking/${bookingId}`,
  });

  yield put(proceedToPayment(booking, supplier));
}

function* redirectToBooking(quote, supplier, toMessage, breadcrumb) {
  if (toMessage) {
    yield fork(trackChatToSupplierEvent, supplier.name);
    yield call(redirectBookingToConversation, {
      bookingId: quote.uuid,
      eventRequestId: quote.event_request_id,
      supplierId: quote.supplier_id,
    });
  } else {
    yield fork(trackProceedToBooking, quote.uuid, supplier);
    yield call(history.push, `/booking/${quote.uuid}/payment`);
  }

  if (breadcrumb) {
    const { url, text } = breadcrumb;

    yield put(breadcrumbSet(url, text));
  }
}

function* onQuoteBookNow({ quote, supplier, toMessage, breadcrumb }) {
  try {
    yield call(redirectToBooking, quote, supplier, toMessage, breadcrumb);
  } catch (err) {
    yield call([console, 'error'], 'Error booking quote:', err.stack);
  }
}

export default function* bookingSaga() {
  yield takeLatest(QUOTE_BOOKED_NOW, onQuoteBookNow);
}
