import { FETCH_SUPPLIERS } from '../constants/action-types';

const initialState = {
  items: [],
  numPages: 0,
};

function vendors(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUPPLIERS:
      return {
        ...state,
        items: action.payload.results,
        numPages: action.payload.numPages,
      };
    default:
      return state;
  }
}

export default vendors;
