import { select, take, race, call } from 'redux-saga/effects';
import { getLoggedIn } from '../selectors/user';

import { LOGOUT_USER, SET_USER } from '../ducks/users';

function* takeLogout() {
  yield take(SET_USER);
  const loggedIn = yield select(getLoggedIn);

  if (loggedIn) {
    yield call(takeLogout);
  }
}

function* takeLogin() {
  yield take(SET_USER);

  const loggedIn = yield select(getLoggedIn);

  if (!loggedIn) {
    yield call(takeLogin);
  }
}

export function* onLoginChange() {
  const loggedIn = yield select(getLoggedIn);

  if (loggedIn) {
    yield race([call(takeLogout), take(LOGOUT_USER)]);

    return false;
  }

  yield call(takeLogin);

  return true;
}
