import {
  TOGGLE_NOTIFICATION,
  RESET_NOTIFICATION,
} from '../constants/action-types';

export const toggleNotification = payload => ({
  type: TOGGLE_NOTIFICATION,
  payload,
});
export const resetNotification = () => ({ type: RESET_NOTIFICATION });
