import { createSelector } from '@reduxjs/toolkit';

import { VISITOR_ROLE, VENDOR_ROLE } from '../constants/roles';

export const getUserId = (state) => state.user.id;

const getUserRole = (state) => state.user.type;
const getUserToken = (state) => state.user.token;

export const getUserIsSupplier = createSelector(
  getUserRole,
  (type) => type === VENDOR_ROLE.name
);

export const getLoggedIn = createSelector(
  getUserId,
  getUserRole,
  (userId, userRole) => Boolean(userId && userRole !== VISITOR_ROLE.name)
);

export const getSessionId = (state) => state.user.sessionID;

const getUserSuppliers = (state) => state.user.suppliers || [];

export const getUserSupplier = createSelector(
  getUserSuppliers,
  (suppliers) => suppliers[0] || {}
);

export const getUserSupplierId = createSelector(getUserSuppliers, (suppliers) =>
  suppliers.length ? suppliers[0].uuid : null
);

export const getNewToken = createSelector(getUserToken, (user) => user);
