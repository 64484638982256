import { uuidRegex } from './uuid';

// Event Names
export const BOOKING_VIEWED = 'Booking Viewed';
export const GET_QUOTES_CLICKED = 'Get Quotes clicked';
export const REQUEST_HIDDEN = 'Request Hidden';
export const MY_QUOTES_VIEW_QUOTE_CLICKED = 'My Quotes View Quote CTA Clicked';
export const QUOTE_SHARED = 'Quote Shared';
export const PAGE_VIEWED = 'Page Viewed';
export const PROCEEDED_TO_PAYMENT = 'Proceeded To Payment';
export const SUPPLIER_QUOTE_SUBMITTED = 'Supplier Quote Submitted';
export const REFERRAL_SHARED = 'Referral Shared';
export const DISINTERMEDIATION_WARNING_SHOWN =
  'Disintermediation Warning Shown';
export const SUPPLIER_PROFILE_LINK_CLICKED = 'Supplier Profile Link Clicked';
export const PUSHY_INCOMPLETES_MODAL_CLOSED = 'Pushy Incompletes Modal Closed';
export const DECLINE_QUOTE_MODAL_OPENED = 'Decline Quote Modal Opened';
export const REQUEST_QUOTE_CTA_CLICKED = 'Request a Quote CTA Clicked';
export const TOGGLE_EVENT_REQUEST_QUOTE_LIMITED_CLICKED =
  'acceptingQuotesSettingChanges';
export const BOOK_NOW_CTA_CLICKED = 'bookNowCtaClicked';
export const MESSAGE_SUPPLIER_CTA_CLICKED = 'messageSupplierCtaClicked';
export const PAYMENT_SUBMITTED = 'Payment submitted';
export const PAYMENT_OPTION_SELECTED = 'paymentOptionSelected';
export const DEPOSIT_AMOUNT_CHANGED = 'depositAmountChanged';
export const CONTACT_DETAILS_CLICKED = 'contactDetailsClicked';
export const CHAT_CTA_CLICKED = 'chatCtaClicked';
export const PAYMENT_DETAILS_EDIT_SELECTED = 'paymentDetailsEditSelected';
// Property names
export const CHAT_TO_SUPPLIER = 'Chat To Supplier';

export const ADD_DRINKS_CLICKED = 'Add Drinks Clicked';
export const ADD_DESSERTS_CLICKED = 'Add Desserts Clicked';
export const ADD_PHOTOGRAPHY_CLICKED = 'Add Photography Clicked';
export const CROSS_SELL_CAROUSEL_CTA_CLICKED =
  'Cross-Sell Carousel CTA Clicked';
export const CHANGED_EVENT_NAME = 'Changed Event Name';
export const CANCELLED_REQUEST = 'Request Cancelled';
export const FAVOURITES_CTA_CLICKED = 'Favourites Banner CTA clicked';
export const FAVOURITES_SUGGESTION_CTA_CLICKED =
  'Favourites Suggestion Banner CTA clicked';
export const ACCOUNT_CREATED = 'Customer Account Created';
export const REQUEST_QUOTE_CARD_CTA_CLICKED = 'requestCardCtaClicked';
export const PAYMENT_PROTECTION_OPTION_CLICKED =
  'paymentProtectionOptionClicked';
export const PAY_BY_INVOICE_REQUEST_SUBMITTED = 'payByInvoiceRequestSubmitted';
export const PAYMENT_DETAILS_STARTED = 'paymentDetailsStarted';
export const BACK_CLICKED = 'backClicked';
export const BREADCRUMB_CLICKED = 'Breadcrumb Clicked';
export const REQUEST_WORKFLOW_TAB_CLICKED = 'requestWorkflowTabClicked';
export const SORT_OPTIONS_CHANGED = 'customerQuoteSortOptionsChanged';
export const TWILIO_CALL_INITIATED = 'twilioCallInitiated';

// PAGE NAMES
export const categories = [
  {
    regex: /\/my-bookings|\/my-payments|\/messages|\/my-favourites|\/settings|\/dashboard/gi,
    type: 'Dashboard',
  },
  {
    regex: /\/suppliers\/dashboard/g,
    type: 'Supplier Dashboard',
  },
  {
    regex: /\/calendar/,
    type: 'Calendar',
  },
  {
    regex: /\/payment\/confirmation/g,
    type: 'Checkout',
    pageName: 'Booking Confirmed',
  },
  {
    regex: new RegExp(`/reviews/${uuidRegex.source}`, 'gi'),
    type: 'Supplier Reviews',
    pageName: 'Review a Supplier',
  },
  {
    regex: /\/reviews(\?|$)/,
    type: 'Supplier Reviews',
    pageName: 'Supplier Create Review Form',
  },
  {
    regex: /\/booking\/.*\/payment/,
    type: 'Checkout',
    pageName: 'Checkout page',
  },

  {
    regex: /\/login/,
    type: 'Login',
    pageName: 'Login page',
  },
];

export const PAGES = {
  MESSAGING_VIEW: 'messagingView',
  QUOTE_VIEW: 'quoteView',
  PAYMENT: 'checkout',
  EVENT_PAGE: 'eventPage',
  EVENT_REQUEST: 'requestPage',
  QUOTE: 'quotePage',
  EVENT_EDIT: 'eventPageEdit',
  BOOKING: 'bookingPage',
  CONVERSATION: 'conversation',
};

// PAGE CONTEXT
export const PAGE_CONTEXT = {
  MESSAGING_VIEW: {
    THREAD_CARD: 'messagingThreadCard',
    SIDEBAR: 'messagingSidebar',
  },
  QUOTE_VIEW: {
    QUOTE_BODY: 'quoteBody',
    QUOTE_FOOTER: 'quoteStickyFooter',
    QUOTE_ITEM_DRAWER: 'quoteItemDrawer',
  },
};

// Quote Card CTA Clicked

export const QUOTE_CARD_CTA_CLICKED = {
  MESSAGE: 'message',
  VIEW_QUOTE: 'viewQuote',
  VIEW_BOOKING: 'viewBooking',
  QUOTE_CARD: 'quoteCard',
} as const;

export type QuoteCardCtas = typeof QUOTE_CARD_CTA_CLICKED[keyof typeof QUOTE_CARD_CTA_CLICKED];
