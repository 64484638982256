import { RECEIVED_SUPPLIER_TAGS } from '../constants/action-types';

const initialState = [];

function supplierTags(state = initialState, action) {
  switch (action.type) {
    case RECEIVED_SUPPLIER_TAGS:
      return { ...state, tags: action.tags };
    default:
      return state;
  }
}

export default supplierTags;
