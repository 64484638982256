import config from '../../app-config';

const { baseUri, isDeployedOnAWS } = config;

let adjustedBaseUri = baseUri;

if (
  typeof window !== 'undefined' &&
  window.location.origin === 'https://togather.com'
) {
  adjustedBaseUri = 'https://togather.com';
}

if (
  typeof window !== 'undefined' &&
  window.location.origin === 'https://staging.togather.com'
) {
  adjustedBaseUri = 'https://staging.togather.com';
}

const MAGAZINE = `${adjustedBaseUri}/blog`;
const BOOKINGS = '/my-bookings';
const MESSAGES = '/messages';
const DASHBOARD = '/dashboard';
const PAYMENTS = '/my-payments';

export default {
  HOME: `${adjustedBaseUri}/`,
  MAGAZINE,
  MAGAZINE_CATEGORY: (id) => `${MAGAZINE}/category/${id}`,
  MAGAZINE_POST: (id) => `${MAGAZINE}/${id}`,
  COMMUNITY: `${adjustedBaseUri}/partners/supplier-community`,
  ENQUIRY_FORM: `${adjustedBaseUri}/enquiry`,
  BROWSE: `${adjustedBaseUri}/suppliers`,
  SUPPLIER_PROFILE: (id) => `${adjustedBaseUri}/suppliers/${id}`,
  SUPPLIER_DASHBOARD: isDeployedOnAWS
    ? '/suppliers/dashboard'
    : `${adjustedBaseUri}/suppliers/dashboard`,
  SUPPLIER_EVENTS: `${adjustedBaseUri}/suppliers/dashboard/open-events`,
  SUPPLIER_EVENT: (id) =>
    `${adjustedBaseUri}/suppliers/dashboard/open-events/${id}`,
  SUPPLIER_EVENT_QUOTE: (id) =>
    `${adjustedBaseUri}/suppliers/dashboard/open-events/${id}/quote`,
  SUPPLIER_QUOTES: `${adjustedBaseUri}/suppliers/dashboard/my-quotes`,
  SUPPLIER_QUOTE: (id) =>
    `${adjustedBaseUri}/suppliers/dashboard/my-quotes/${id}`,
  SUPPLIER_MY_SERVICE: `${adjustedBaseUri}/suppliers/dashboard/my-service`,
  SUPPLIER_QUOTE_TEMPLATES: `${adjustedBaseUri}/suppliers/dashboard/my-templates`,
  SUPPLIER_QUOTE_TEMPLATE_NEW: `${adjustedBaseUri}/suppliers/dashboard/my-templates/create`,
  SUPPLIER_QUOTE_TEMPLATE_EDIT: (id) =>
    `${adjustedBaseUri}/suppliers/dashboard/my-templates/${id}/edit`,
  SUPPLIER_BOOKINGS: `${adjustedBaseUri}/suppliers/dashboard/my-bookings`,
  SUPPLIER_BOOKING: (id) =>
    `${adjustedBaseUri}/suppliers/dashboard/my-bookings/${id}`,
  SUPPLIER_BOOKINGS_PENDING: '/suppliers/dashboard/my-bookings/pending',
  SUPPLIER_BOOKINGS_CONFIRMED: '/suppliers/dashboard/my-bookings/confirmed',
  // SUPPLIER_DOCUMENT: document => `${baseSupplierDocumentUri}/${document}`,
  SUPPLIER_MANAGE_ACCOUNT: `${adjustedBaseUri}/suppliers/dashboard/manage`,
  SUPPLIER_MANAGE_ACCOUNT_SECTION: (id) =>
    `${adjustedBaseUri}/suppliers/dashboard/manage/${id}`,
  SUPPLIER_PAYMENTS: `${adjustedBaseUri}/suppliers/dashboard/payments`,
  REFER_AN_EVENT: '/suppliers/dashboard/refer',
  FAVOURITES: '/my-favourites',
  LOGIN: `${adjustedBaseUri}/login`,
  LOGOUT: `${adjustedBaseUri}/logout`,
  RESET_PASSWORD: '/reset-password',
  RESET_ENQUIRY_PASSWORD_SUCCESS: '/reset-enquiry-password-success',
  SIGNUP: '/signup',
  CHANGE_PASSWORD: '/change-password',
  DASHBOARD,
  CUSTOMER_EVENT: (eventId) => `${DASHBOARD}/${eventId}`,
  CUSTOMER_EDIT_EVENT: (eventId) => `${DASHBOARD}/${eventId}/edit`,
  CUSTOMER_EVENT_REQUEST: (eventId, requestId) =>
    `${DASHBOARD}/${eventId}/${requestId}`,
  CUSTOMER_QUOTE: (eventId, requestId, quoteId) =>
    `${DASHBOARD}/${eventId}/${requestId}/${quoteId}`,
  PAYMENTS,
  PAYMENT: (id) => `${PAYMENTS}/${id}`,
  PAYMENT_CONFIRMATION: (bookingId) => `/payment/confirmation/${bookingId}`,
  BOOKINGS,
  BOOKING: (id) => `${BOOKINGS}/${id}`,
  MESSAGES,
  MESSAGE: (id) => `${MESSAGES}/${id}`,
  CALENDAR: `${adjustedBaseUri}/suppliers/dashboard/calendar`,
  SETTINGS: '/settings',
  FEEDBACK: 'https://portal.prodpad.com/bf8f0350-1e4f-11e9-8b8d-0288f735e5b9',
  REVIEWS: `${adjustedBaseUri}/suppliers/dashboard/reviews`,
  CONTACT: `${adjustedBaseUri}/contact`,
  SITEMAP: `${adjustedBaseUri}/sitemap`,
  PARTNERS: `${adjustedBaseUri}/partners`,
  CAREERS: 'https://togather.workable.com/',
  TERMS: `${adjustedBaseUri}/terms`,
  PARTNER_TERMS: `${adjustedBaseUri}/partner-terms`,
  PRIVACY: `${adjustedBaseUri}/privacy`,
  FAQS: 'https://togather.freshdesk.com/support/home',
  VAT_RECEIPT: '/supplier-receipt',
  INFO: (id) => `${adjustedBaseUri}/about/${id}`,
  INFO_PAGES: {
    ABOUT: 'about-us',
    WHY_FEAST_IT: 'why-togather',
    PICKING_OUR_SUPPLIERS: 'picking-our-suppliers',
    HOW_WE_WORK: 'how-we-work',
    SAFETY_SECURITY: 'safety-security',
  },
  BECOME_PARTNER: `${adjustedBaseUri}/about/become-a-partner`,
  EVENT: (category, id) => `${adjustedBaseUri}/${category}/${id}`,
  VERTICAL: (id) => `${adjustedBaseUri}/events/${id}`,
  UPDATES: 'https://headwayapp.co/togather-updates',
  SUPPLIER_VOICE_CALLING:
    'https://togather.freshdesk.com/en/support/solutions/articles/64000257748-voice-calling-customers',
  CARBON_LANDING_PAGE: `${adjustedBaseUri}/events/carbon-offset-your-event`,
  BOOK_NOW: (id) => `/booking/${id}/payment`,
  SHARE_QUOTE: (id) => `${adjustedBaseUri}/my-quote/${id}`,
  REVIEW_EVENT: (id) => `${BOOKINGS}/${id}/review`,
  PHONE: (phoneNumber) => `tel:${phoneNumber}`,
  EMAIL: (emailAddress) => `mailto:${emailAddress}`,
  FACEBOOK_LINK: 'https://www.facebook.com/Feastitofficial',
  TWITTER_LINK: 'https://twitter.com/Feast_it',
  INSTAGRAM_LINK: 'https://www.instagram.com/feast._.it/',
  PUBLIC_EVENTS: `${adjustedBaseUri}/suppliers/dashboard/public-events`,
  PROTECT_PLUS_INFO: '/magazine/feast-it-protect-plus-explained',
  LANDING: (category, id) => `${adjustedBaseUri}/${category}/${id}`,
  ABOUT: (id) => `${adjustedBaseUri}/about/${id}`,
};
