import { FETCH_BOOKINGS, RESET } from '../constants/action-types';

const initialState = {
  items: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_BOOKINGS:
      return { ...state, items: action.payload.results };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
