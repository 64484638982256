import { SET_METADATA, RESET_METADATA } from '../constants/action-types';

function setMetadata(pathname, state) {
  return {
    type: SET_METADATA,
    meta: { pathname },
    payload: state,
  };
}

export const resetMetadata = () => ({
  type: RESET_METADATA,
});

export default setMetadata;
