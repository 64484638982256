import {
  FETCH_BOOKING,
  ACCEPT_BOOKING,
  DECLINE_BOOKING,
  QUOTE_BOOKED_NOW,
  UPDATE_BOOKING,
  RESET,
  FETCH_QUOTE_ID,
} from '../constants/action-types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case QUOTE_BOOKED_NOW:
      return initialState;
    case FETCH_BOOKING:
    case ACCEPT_BOOKING:
    case DECLINE_BOOKING:
      return action.payload;
    case UPDATE_BOOKING:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_QUOTE_ID:
      return {
        ...state,
        quoteId: action.payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}
