import { COOKIE_MANAGER } from './cookieHubManager';
import { EnableParams } from '../../types/cookiehub';

export const handleCookieConsentChange = (
  enableParams: EnableParams
): boolean => {
  Object.keys(COOKIE_MANAGER).forEach((category) => {
    if (window.cookiehub?.hasConsented(category)) {
      COOKIE_MANAGER[category].enable(enableParams);
    } else {
      COOKIE_MANAGER[category].disable();
    }
  });

  return Object.keys(COOKIE_MANAGER).some(
    (category) => !window.cookiehub?.hasConsented(category)
  );
};
