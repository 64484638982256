import { takeLatest, call, put } from 'redux-saga/effects';
import { request } from './api';

import {
  FETCH_EVENT_REQUESTS,
  GET_ALL_EVENT_REQUESTS,
  FETCH_EVENT_REQUEST,
  GET_EVENT_REQUEST,
  GET_EVENT_REQUEST_ROBEX,
  FETCH_EVENT_REQUEST_ROBEX,
  FETCH_EVENT_REQUEST_START,
  GET_EVENT_REQUEST_UNREAD_MESSAGES,
  FETCH_EVENT_REQUEST_UNREAD_MESSAGES,
  FETCH_EVENT_REQUEST_ERROR,
} from '../constants/action-types';

function* fetchRequests({ eventId }) {
  const requests = yield call(request, {
    url: `/event-requests/${eventId}/event`,
    method: 'GET',
  });
  yield put({
    type: FETCH_EVENT_REQUESTS,
    payload: requests,
  });
}

function* fetchRequest({ requestId, params = {} }) {
  yield put({
    type: FETCH_EVENT_REQUEST_START,
  });

  try {
    const event = yield call(
      request,
      {
        url: `/event-requests/${requestId}`,
        method: 'GET',
        params,
      },
      { throwErr: true }
    );

    yield put({
      type: FETCH_EVENT_REQUEST,
      payload: event,
    });
  } catch (err) {
    yield put({ type: FETCH_EVENT_REQUEST_ERROR, payload: err.response });
  }
}

function* fetchEventRequestRobex({ requestId }) {
  const suppliers = yield call(request, {
    url: `/event-requests/${requestId}/suppliers`,
    method: 'GET',
  });
  yield put({
    type: FETCH_EVENT_REQUEST_ROBEX,
    payload: suppliers,
  });
}

function* fetchEventRequestUnreadMessages({ requestId }) {
  const unreadMessages = yield call(request, {
    url: `/conversations/unreadCountForRequest/${requestId}`,
    method: 'GET',
  });

  yield put({
    type: FETCH_EVENT_REQUEST_UNREAD_MESSAGES,
    payload: unreadMessages,
  });
}

export default function* eventRequestSaga() {
  yield takeLatest(GET_ALL_EVENT_REQUESTS, fetchRequests);
  yield takeLatest(GET_EVENT_REQUEST, fetchRequest);
  yield takeLatest(GET_EVENT_REQUEST_ROBEX, fetchEventRequestRobex);
  yield takeLatest(
    GET_EVENT_REQUEST_UNREAD_MESSAGES,
    fetchEventRequestUnreadMessages
  );
}
