import React from 'react';
import { Flex, Box, rem, Spinner, base } from '@feast-it/pesto';
import { Logo, baseTheme } from '@feast-it/ui';
import { ThemeProvider } from 'emotion-theming';

const HEADER_HEIGHT = 60;

const LayoutSkeleton = () => (
  <ThemeProvider theme={base}>
    <Flex
      alignItems="center"
      justifyContent="space-between"
      height={HEADER_HEIGHT}
      borderBottom="1px solid"
      borderBottomColor="greyLight"
      px={{ _: 'gutter._', s: 'gutter.s', m: 6 }}
      py={9}
    >
      <ThemeProvider theme={baseTheme}>
        <Logo fill="black" name="Togather" width="160px" />
      </ThemeProvider>

      <Box
        display={{ _: 'flex', m: 'none' }}
        height={rem(40)}
        width={rem(40)}
        borderRadius="50%"
        bg="black"
        opacity="0.05"
      />

      <Box
        display={{ _: 'none', m: 'flex' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          height={rem(20)}
          width={rem(80)}
          bg="black"
          opacity="0.05"
          borderRadius={0}
          ml={6}
        />
        <Box
          height={rem(20)}
          width={rem(80)}
          bg="black"
          opacity="0.05"
          borderRadius={0}
          ml={6}
        />
        <Box
          height={rem(20)}
          width={rem(80)}
          bg="black"
          opacity="0.05"
          borderRadius={0}
          ml={6}
        />
        <Box
          height={rem(40)}
          width={rem(100)}
          bg="black"
          opacity="0.05"
          borderRadius={rem(40)}
          ml={6}
        />
        <Box
          height={rem(40)}
          width={rem(100)}
          bg="black"
          opacity="0.05"
          borderRadius={rem(40)}
          ml={6}
        />
      </Box>
    </Flex>

    <Flex
      alignItems="center"
      justifyContent="center"
      height={`calc(100vh - ${rem(HEADER_HEIGHT)})`}
    >
      <Spinner size={60} />
    </Flex>
  </ThemeProvider>
);

export default LayoutSkeleton;
