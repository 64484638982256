import { FETCH_REFERRALS } from '../constants/action-types';

const initialState = {
  bookings: [],
  enquiries: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_REFERRALS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
