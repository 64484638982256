import { fork, debounce, call, put } from 'redux-saga/effects';
import ReactGA from 'react-ga4';

import { request } from '../sagas/api';

const METHODS_REQUESTED = 'payments/METHODS_REQUESTED';
const METHODS_RECEIVED = 'payments/METHODS_RECEIVED';

const METHOD_UPDATE_REQUESTED = 'payments/METHOD_UPDATE_REQUESTED';
const METHOD_UPDATE_RECEIVED = 'payments/METHOD_UPDATE_RECEIVED';
const METHOD_UPDATE_ERRORED = 'payments/METHOD_UPDATE_ERRORED';

export const idKeyAll = 'all';

export const methodsRequested = () => ({
  type: METHODS_REQUESTED,
});

export const methodsReceived = paymentMethods => ({
  type: METHODS_RECEIVED,
  paymentMethods,
});

export const methodUpdateRequested = (methodId = idKeyAll, paymentMethod) => ({
  type: METHOD_UPDATE_REQUESTED,
  methodId,
  paymentMethod,
});

function* onRequestMethods() {
  const { paymentMethods } = yield call(request, {
    url: '/payments-v3/methods',
  });

  yield put(methodsReceived(paymentMethods));
}

function* onUpdateMethod({ methodId, paymentMethod }) {
  try {
    yield call(
      request,
      {
        url: '/payments-v3/methods',
        method: 'POST',
        data: {
          paymentMethodId: paymentMethod.id,
        },
      },
      { throwErr: true }
    );

    if (methodId !== idKeyAll) {
      yield call(
        request,
        {
          url: `/payments-v3/methods/${methodId}`,
          method: 'DELETE',
        },
        { throwErr: true }
      );
    }

    yield fork([ReactGA, 'event'], {
      category: 'Account',
      action: 'Update',
      label: 'New Card Linked',
      value: 1,
    });

    yield put({
      type: METHOD_UPDATE_RECEIVED,
      methodId,
    });

    yield put(methodsRequested());
  } catch (err) {
    const { data } = err.response;

    const message =
      data.code && data.message
        ? `Something went wrong! Sorry about that: Error ${data.code} - ${data.message}`
        : err.message;

    yield put({
      type: METHOD_UPDATE_ERRORED,
      methodId,
      err: message,
    });
  }
}

export function* paymentsSaga() {
  yield debounce(100, METHODS_REQUESTED, onRequestMethods);
  yield debounce(100, METHOD_UPDATE_REQUESTED, onUpdateMethod);
}

export const initialState = {
  loadingMethods: false,
  methods: [],
  methodStatus: {
    [idKeyAll]: {},
  },
};

export default function paymentsReducer(state = initialState, action) {
  switch (action.type) {
    case METHODS_REQUESTED:
      return { ...state, loadingMethods: true };
    case METHODS_RECEIVED:
      return {
        ...state,
        loadingMethods: false,
        methods: action.paymentMethods || [],
      };

    case METHOD_UPDATE_REQUESTED:
      return {
        ...state,
        methodStatus: {
          ...state.methodStatus,
          [action.methodId]: { loading: true, error: null },
        },
      };

    case METHOD_UPDATE_RECEIVED:
      return {
        ...state,
        methodStatus: {
          ...state.methodStatus,
          [action.methodId]: { loading: false, error: null },
        },
      };

    case METHOD_UPDATE_ERRORED:
      return {
        ...state,
        methodStatus: {
          ...state.methodStatus,
          [action.methodId]: { loading: false, error: action.err },
        },
      };

    default:
      return state;
  }
}
