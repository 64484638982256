import cookies from 'js-cookie';
import { datadogRum } from '@datadog/browser-rum';
import mixpanel from 'mixpanel-browser';
import {
  initGA,
  setCookie,
  updateSegmentConsentState,
} from '../../modules/analytics/analytics';
import { CookieManager } from '../../types/cookiehub';
import { initDatadogLogging, initDatadogRum } from '../../modules/monitoring';
import { trackingCookieNames } from '../../constants/cookie-consent';

export const COOKIE_MANAGER: CookieManager = {
  analytics: {
    enable: ({ queries, user }) => {
      // Set AWIN cookies if awc and source in URL query params from affiliate link
      if (queries?.awc && queries?.source) {
        setCookie('awc', queries.awc as string);
        setCookie('utm_source', queries.source as string);
      }

      initGA();
      updateSegmentConsentState();

      // Opt in to Mixpanel tracking
      mixpanel.opt_in_tracking();

      // Initialise Datadog logging & RUM
      initDatadogLogging();
      initDatadogRum(user);
    },
    disable: () => {
      // Remove cookies by name
      Object.values(trackingCookieNames).forEach((cookieName) =>
        cookies.remove(cookieName)
      );

      updateSegmentConsentState();

      // Opt out of Mixpanel tracking
      mixpanel.opt_out_tracking();

      datadogRum.stopSession();
      datadogRum.stopSessionReplayRecording();
    },
  },
};
