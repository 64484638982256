import { SubmissionError } from 'redux-form';

export function reduxFormError(err) {
  if (err.response) {
    throw new SubmissionError({
      _error: `${err.response.message} ${
        err.response.info ? err.response.info : ''
      }`,
    });
  } else {
    throw new SubmissionError({
      _error:
        'Oops! There is a problem with the server, please try again later!',
    });
  }
}
