import {
  FETCH_SETTING,
  RECEIVED_SETTING,
  SET_SETTING,
} from '../constants/action-types';

const initialState = {
  isLoading: 0,
};

function vendors(state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTING:
      return { ...state, isLoading: state.isLoading + 1 };
    case RECEIVED_SETTING:
      return {
        ...state,
        isLoading: state.isLoading - 1,
        [action.payload.option_name]: action.payload.option_value,
      };
    case SET_SETTING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export default vendors;
