import axios from 'axios';
import cookie from 'js-cookie';

import appConfig from '../../app-config';
import { AUTH_TOKEN, USER_ID } from '../constants-togather/auth';
import {
  getLatestCognitoToken,
  isCognitoAuthentication,
} from '../helpers/auth/cognito';

export const api = axios.create();

const extendConfig = async (token, originalConfig) => {
  const config = { ...originalConfig };

  config.baseURL = appConfig.baseApiUri;

  if (token) {
    if (isCognitoAuthentication(token)) {
      config.headers['x-new-auth'] = true;
      config.headers['x-user-id'] = cookie.get(USER_ID) ?? '';

      config.headers.Authorization = `Bearer ${await getLatestCognitoToken()}`;
    } else {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
};

api.interceptors.request.use((config) => {
  const token = cookie.get(AUTH_TOKEN);

  return extendConfig(token, config);
});
