import {
  SUPPLIER_PAGINATED_REVIEW_LIST_REQUESTED,
  SUPPLIER_REVIEW_LIST_RECEIVED,
  SUPPLIER_PAGINATED_REVIEW_LIST_RECEIVED,
  SUPPLIER_REVIEW_CREATED,
  SUPPLIER_REVIEW_READ,
  SUPPLIER_REVIEW_UPDATED,
  SUPPLIER_REVIEW_AUTHENTICATED,
  SUPPLIER_REVIEW_RECEIVED,
  SUPPLIER_REVIEW_ERRORED,
} from '../constants/action-types';

export const listPaginatedRequested = (supplierId, pageNumber, limit) => ({
  type: SUPPLIER_PAGINATED_REVIEW_LIST_REQUESTED,
  payload: { supplierId, pageNumber, limit },
});

export const listReceived = items => ({
  type: SUPPLIER_REVIEW_LIST_RECEIVED,
  items,
});

export const paginatedlistReceived = items => ({
  type: SUPPLIER_PAGINATED_REVIEW_LIST_RECEIVED,
  items,
});

export const created = data => ({
  type: SUPPLIER_REVIEW_CREATED,
  data,
});

export const read = (uuid, token) => ({
  type: SUPPLIER_REVIEW_READ,
  uuid,
  token,
});

export const updated = (uuid, data, token = null, metadata = {}) => ({
  type: SUPPLIER_REVIEW_UPDATED,
  uuid,
  data,
  token,
  metadata,
});

export const authenticated = (uuid, token) => ({
  type: SUPPLIER_REVIEW_AUTHENTICATED,
  uuid,
  token,
});

export const received = (review, metadata = {}) => ({
  type: SUPPLIER_REVIEW_RECEIVED,
  review,
  metadata,
});

export const errored = (err, metadata = {}) => ({
  type: SUPPLIER_REVIEW_ERRORED,
  err,
  metadata,
});
