import mergeWith from 'lodash.mergewith';
import merge from 'lodash.merge';
import { union } from '../libs/helpers';

import { INIT_FILTER, SET_ACTIVE_FILTER } from '../constants/action-types';

export function resetFilterGroup(filters, groupId) {
  return {
    ...filters,
    [groupId]: [],
  };
}

export function mergeFilterGroup(filters, groupId, id, value) {
  return mergeWith({}, filters, { [groupId]: [id] }, objValue => {
    if (Array.isArray(objValue)) {
      if (value) {
        return union(objValue, [id]);
      }
      return objValue.filter(val => val !== id);
    }
    return undefined;
  });
}

function setActiveFilters(state, groupId, filtersIds = []) {
  if (!(Array.isArray(filtersIds) && filtersIds.length)) {
    return state;
  }

  const newFilters = state[groupId].map(({ id }) => {
    if (filtersIds.find(_id => id === _id)) {
      return { id, value: true };
    }
    return { id, value: false };
  });
  return { ...state, [groupId]: newFilters };
}

export function isAllFiltersSelected(state, groupId) {
  return state[groupId] && !state[groupId].find(filter => !filter.value);
}

export function isNoneFiltersSelected(state, groupId) {
  return state[groupId] && !state[groupId].find(filter => filter.value);
}

/**
 * Selectors
 */
export function getActiveFilters(state, groupId) {
  if (
    isAllFiltersSelected(state, groupId) ||
    isNoneFiltersSelected(state, groupId)
  ) {
    return undefined;
  }

  return (
    state[groupId] &&
    state[groupId].filter(filter => filter.value).map(filter => filter.id)
  );
}

export function getPriceFilterValue(pricesCustomTags, filter, index) {
  switch (index) {
    case 0:
    default:
      return filter ? !filter.length : true;
    case 1:
      return filter
        ? filter.indexOf(pricesCustomTags[0].id) > -1 &&
            filter.indexOf(pricesCustomTags[1].id) > -1
        : false;
    case 2:
      return filter
        ? filter.indexOf(pricesCustomTags[1].id) > -1 &&
            filter.indexOf(pricesCustomTags[2].id) > -1
        : false;
  }
}

function vendors(state = {}, action) {
  const { type, meta, payload } = action;

  switch (type) {
    case INIT_FILTER:
      return merge({}, state, {
        [meta.groupId]: payload,
      });
    case SET_ACTIVE_FILTER: {
      return setActiveFilters(state, meta.groupId, payload);
    }
    default:
      return state;
  }
}

export default vendors;
