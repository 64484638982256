import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import cookies from 'js-cookie';

import config from '../../app-config';
import { AUTH_TOKEN, USER_ID } from '../constants-togather/auth';
import {
  getLatestCognitoToken,
  isCognitoAuthentication,
  isImpersonationSession,
} from '../helpers/auth/cognito';

const httpLink = createHttpLink({
  uri: config.graphqlApi,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from cookies if it exists
  const authToken = cookies.get(AUTH_TOKEN);
  const userId = cookies.get(USER_ID);

  if (authToken && isCognitoAuthentication(authToken)) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${await getLatestCognitoToken()}`,
        'x-new-auth': true,
        'x-user-id': userId,
        'x-impersonated-user-id': isImpersonationSession() ? userId : null,
      },
    };
  }

  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
