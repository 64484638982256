import {
  calculateBaseToFull,
  toPounds,
  calculateBaseTotal,
  toPennies,
  calculateRateValue,
  calculateCommissionFee,
  addRate,
} from '@feast-it/finance';
import { VAT_RATE } from '../constants-togather/finance';

// For tracking purposes
export const getQuoteAmount = (quote) =>
  calculateBaseToFull(toPounds(calculateBaseTotal(quote)), quote.service_fee);

// Used to show suppliers a cost breakdown whilst quoting
export const calculateCostBreakdown = ({
  total,
  commission,
  service_fee,
  old_commission,
}) => {
  const traderValue = toPennies(total) || 0;

  const commissionValue = old_commission
    ? calculateRateValue(traderValue, commission)
    : calculateCommissionFee(traderValue, commission);

  const baseTotal = traderValue + commissionValue;
  const customerTotal = addRate(baseTotal, service_fee);
  const serviceValue = calculateRateValue(baseTotal, service_fee);

  return {
    traderValue,
    baseTotal,
    customerTotal,
    commissionValue,
    serviceValue,
  };
};

export const calculateVatTotals = ({
  priceIncludingVat,
  priceExcludingVat,
}) => ({
  priceIncludingVat,
  priceExcludingVat,
  totalVat: Number((priceIncludingVat - priceExcludingVat).toFixed(2)),
});

export const roundToTwoDecimalPlaces = (num) => Math.round(num * 100) / 100;

export const excludeVat = (priceIncludingVat) =>
  calculateVatTotals({
    priceIncludingVat: roundToTwoDecimalPlaces(priceIncludingVat),
    priceExcludingVat: roundToTwoDecimalPlaces(
      priceIncludingVat / (1 + VAT_RATE)
    ),
  });

export const includeVat = (priceExcludingVat) =>
  calculateVatTotals({
    priceIncludingVat: roundToTwoDecimalPlaces(
      priceExcludingVat * (1 + VAT_RATE)
    ),
    priceExcludingVat: roundToTwoDecimalPlaces(priceExcludingVat),
  });
