import { replaceAtIndex, removeAtIndex } from '../libs/helpers';

import { RESET_NOTIFICATION } from '../constants/action-types';

export const initialState = {
  loading: false,
  loadingCreate: false,
  loadingUpdate: false,
  loadingDelete: false,
  error: false,
  events: [],
  unavailableUuid: null,
};

export const REQUESTED = '@@supplierCalendar/REQUESTED';
const RECEIVED = '@@supplierCalendar/RECEIVED';
export const CREATE_REQUESTED = '@@supplierCalendar/CREATE_REQUESTED';
export const CREATE_RECEIVED = '@@supplierCalendar/CREATE_RECEIVED';
export const UPDATE_REQUESTED = '@@supplierCalendar/UPDATE_REQUESTED';
export const UPDATE_RECEIVED = '@@supplierCalendar/UPDATE_RECEIVED';
export const DELETE_REQUESTED = '@@supplierCalendar/DELETE_REQUESTED';
export const DELETE_RECEIVED = '@@supplierCalendar/DELETE_RECEIVED';
export const UNAVAILABLE_MARKED = '@@supplierCalendar/UNAVAILABLE_MARKED';
export const UNAVAILABLE_RECEIVED = '@@supplierCalendar/UNAVAILABLE_RECEIVED';

export const calendarsRequested = (
  start = null,
  end = null,
  calendarId = null
) => ({
  type: REQUESTED,
  start,
  end,
  calendarId,
});

export const calendarsReceived = (events = null) => ({
  type: RECEIVED,
  events,
});

export const createRequested = newEvent => ({
  type: CREATE_REQUESTED,
  newEvent,
});

export const createReceived = newEvent => ({
  type: CREATE_RECEIVED,
  newEvent,
});

export const updateRequested = updatedEvent => ({
  type: UPDATE_REQUESTED,
  updatedEvent,
});

export const updateReceived = updatedEvent => ({
  type: UPDATE_RECEIVED,
  updatedEvent,
});

export const deleteRequested = uuid => ({ type: DELETE_REQUESTED, uuid });
export const deleteReceived = uuid => ({ type: DELETE_RECEIVED, uuid });

export const unavailableMarked = date => ({
  type: UNAVAILABLE_MARKED,
  date,
});

export const unavailableReceived = uuid => ({
  type: UNAVAILABLE_RECEIVED,
  uuid,
});

function onReceived(state, { events }) {
  const nextState = { ...state, loading: false };

  if (!events) {
    return { ...nextState, error: true, events: [] };
  }

  return { ...nextState, error: false, events };
}

const onCreateReceived = (state, { newEvent }) => ({
  ...state,
  loadingCreate: false,
  events: [...state.events, newEvent],
});

const onUpdateReceived = (state, { updatedEvent: { uuid, ...data } }) => ({
  ...state,
  loadingUpdate: false,
  events: replaceAtIndex(
    state.events,
    state.events.findIndex(({ uuid: compare }) => compare === uuid),
    last => ({ ...last, ...data })
  ),
});

const onDeleteReceived = (state, { uuid }) => ({
  ...state,
  loadingDelete: false,
  events: removeAtIndex(
    state.events,
    state.events.findIndex(({ uuid: compare }) => compare === uuid)
  ),
});

export default function supplierCalendar(state = initialState, action) {
  switch (action.type) {
    case REQUESTED:
      return { ...state, loading: true, error: false };

    case RECEIVED:
      return onReceived(state, action);

    case CREATE_REQUESTED:
      return { ...state, loadingCreate: true };
    case CREATE_RECEIVED:
      return onCreateReceived(state, action);

    case UPDATE_REQUESTED:
      return { ...state, loadingUpdate: true };
    case UPDATE_RECEIVED:
      return onUpdateReceived(state, action);

    case DELETE_REQUESTED:
      return { ...state, loadingDelete: true };
    case DELETE_RECEIVED:
      return onDeleteReceived(state, action);

    case UNAVAILABLE_RECEIVED:
      return { ...state, unavailableUuid: action.uuid };

    case RESET_NOTIFICATION:
      return { ...state, unavailableUuid: null };

    default:
      return state;
  }
}
