import {
  FETCH_CUSTOMER_EVENT,
  RESET_CUSTOMER_EVENT,
  RESET,
} from '../constants/action-types';

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_CUSTOMER_EVENT:
      return action.payload;
    case RESET_CUSTOMER_EVENT:
    case RESET:
      return {};
    default:
      return state;
  }
}
