import React from 'react';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import cookies from 'js-cookie';
import { render } from 'react-dom';
import createSagaMiddleware from 'redux-saga';
import { createTracker } from 'redux-segment';

import { fetchUser, setUserToken } from './js/ducks/users';
import reducer from './js/reducers';
import rootSaga from './js/sagas';

import HttpService from './js/services/http-service';
import PrismicService from './js/services/prismic-service';
import './js/modules/cognito';

import { createAuthorization } from './js/httpMiddleware/authorization';
import jsonPayload from './js/httpMiddleware/json-payload';

import { AUTH_TOKEN } from './js/constants-togather/auth';

import config from './app-config';

import App from './js/app';

import './css/app.scss';

import './css/fonts.scss';

// Polyfill for IntersectionObserver
require('intersection-observer');

// Usual Redux stuff
const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware, ...getDefaultMiddleware(), createTracker()];

const store = configureStore({
  devTools: config.deployedEnvironment === 'local',
  reducer,
  middleware,
});

sagaMiddleware.run(rootSaga);

//  Middlewares specific to the custom http service (see http-service.js)
const httpMiddleware = [jsonPayload, createAuthorization()];

export const httpService = new HttpService(
  config.baseApiUri,
  httpMiddleware,
  store
);

export const prismicService = new PrismicService();

const token = cookies.get(AUTH_TOKEN);

// if we have an existing identity then we should update it
if (token) {
  store.dispatch(setUserToken(token));
  // Check that token is still valid, otherwise user will be logged out
  store.dispatch(fetchUser(token));
}

const renderApp = (Component) =>
  render(<Component store={store} />, document.getElementById('app'));

renderApp(App);

if (module.hot) {
  module.hot.accept('./js/app', () => {
    // eslint-disable-next-line global-require
    renderApp(require('./js/app').default);
  });

  module.hot.accept('./js/reducers', () => {
    // eslint-disable-next-line global-require
    store.replaceReducer(require('./js/reducers').default);
  });
}
