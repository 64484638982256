import { FETCH_REQUEST_SUMMARY } from '../constants/action-types';

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_REQUEST_SUMMARY:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
