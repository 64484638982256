export default {
  baseApiUri: process.env.REACT_APP_API_BASE_URI,
  baseUri: process.env.REACT_APP_BASE_URI,
  isDevelopmentMode: (process.env.REACT_APP_BASE_URI ?? '').includes(
    'localhost'
  ),
  // baseSupplierDocumentUri: process.env.REACT_APP_SUPPLIER_DOCUMENTS_BASE, //CTA dwnld
  stripePublishKey: process.env.REACT_APP_STRIPE_PUBLISH_KEY,
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  prismicUri: process.env.REACT_APP_PRISMIC_URI,
  prismicKey: process.env.REACT_APP_PRISMIC_KEY,
  advancedFilters: {
    cuisine: { id: '3' }, // id to be matched with API tags
    dietary: { id: '2' }, // id to be matched with API tags
    type: [
      {
        id: 'Street Food',
        label: 'Street Food',
      },
      {
        id: 'Restaurants + Private Chefs',
        label: 'Restaurants & Private Chefs',
      },
      {
        id: 'Drinks',
        label: 'Drinks',
      },
      {
        id: 'Photographers',
        label: 'Photographers',
      },
    ],
    type_2: [
      {
        id: 'Indoor',
        label: 'Indoor',
      },
      {
        id: 'Outdoor',
        label: 'Outdoor',
      },
    ],
    menuPriceBand: [
      { id: '£', label: 'All' },
      { id: '££', label: '£ - ££' },
      { id: '£££', label: '££ - £££' },
    ],
  },
  resultsLimitBlog: 9,
  resultsLimitVendors: 18,
  resultsLimitEvents: 10,
  cookieSecure: process.env.REACT_APP_COOKIE_SECURE,
  OLD_COMMISSION_FLAG: false,
  blockCrawlers: true,
  enableNewSupplierDashboard: true,
  headwayId: process.env.REACT_APP_HEADWAY_ID,
  plaintextSupplierDescriptions: true,
  graphqlApi: process.env.REACT_APP_GRAPHQL_API_BASE_URI,
  deployedEnvironment: process.env.REACT_APP_DEPLOYED_ENVIRONMENT || 'local',
  datadogApplicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  datadogClientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  mixpanelProjectToken: process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN,
  isDeployedOnAWS: process.env.NEW_AWS_ACCOUNT,
  segmentKey: process.env.REACT_APP_SEGMENT_KEY ?? '',
  cognitoPoolDomain: process.env.REACT_APP_COGNITO_POOL_DOMAIN,
  cognitoPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
  cognitoClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
};
