import { replaceAtIndex } from '../libs/helpers';

import {
  SUPPLIER_PAGINATED_REVIEW_LIST_REQUESTED,
  SUPPLIER_REVIEW_LIST_RECEIVED,
  SUPPLIER_PAGINATED_REVIEW_LIST_RECEIVED,
  SUPPLIER_REVIEW_CREATED,
  SUPPLIER_REVIEW_READ,
  SUPPLIER_REVIEW_UPDATED,
  SUPPLIER_REVIEW_AUTHENTICATED,
  SUPPLIER_REVIEW_RECEIVED,
  SUPPLIER_REVIEW_ERRORED,
} from '../constants/action-types';

const initialState = {
  items: [],
  editing: null,
  error: null,
  loading: false,
  updated: false,
};

function onReviewReceived(
  state,
  { review, metadata: { uuid, single = false, updated = state.updated } }
) {
  const nextState = {
    ...state,
    loading: false,
    updated,
  };

  if (single) {
    return {
      ...nextState,
      editing: { ...(state.editing || {}), ...review },
    };
  }
  if (uuid) {
    return {
      ...nextState,
      items: replaceAtIndex(
        state.items,
        state.items.findIndex(({ uuid: compare }) => compare === uuid),
        review
      ),
    };
  }

  return {
    ...nextState,
    items: [...state.items, review],
  };
}

export default function supplierReviews(state = initialState, action) {
  switch (action.type) {
    case SUPPLIER_PAGINATED_REVIEW_LIST_REQUESTED:
    case SUPPLIER_REVIEW_CREATED:
    case SUPPLIER_REVIEW_READ:
    case SUPPLIER_REVIEW_UPDATED:
    case SUPPLIER_REVIEW_AUTHENTICATED:
      return { ...state, loading: true };

    case SUPPLIER_REVIEW_LIST_RECEIVED:
      return { ...state, loading: false, items: action.items };

    case SUPPLIER_PAGINATED_REVIEW_LIST_RECEIVED:
      return { ...state, loading: false, items: action.items.results };

    case SUPPLIER_REVIEW_RECEIVED:
      return onReviewReceived(state, action);
    case SUPPLIER_REVIEW_ERRORED:
      return {
        ...state,
        loading: false,
        error: action.err,
      };

    default:
      return state;
  }
}
