import compose from 'just-compose';
import ReactGA from 'react-ga4';

import { uuidRegex } from '../constants/uuid';
import { CHAT_TO_SUPPLIER } from '../constants/analytics';
import { trackEvent } from '../modules/analytics/analytics';

export function trackFieldErrors(formName, fields) {
  Object.keys(fields).forEach((key) => {
    ReactGA.event({
      category: `Abandoned Form: ${formName}`,
      action: `Error: ${fields[key]}`,
      label: `Field: ${key}`,
    });
  });
}

const replaceUuids = (value) => value.replace(uuidRegex, '<uuid>');

const shortIdRegex = /[0-9A-Z]{7}/g;
const replaceShortIds = (value) => value.replace(shortIdRegex, '<shortId>');

const replaceDates = (value) =>
  value.replace(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g, '<date>');

const replaceSearchPage = (value) =>
  value.replace(/search\/([0-9]+)(\??)/, 'search/<page>$2');
const replacePostcode = (value) =>
  value.replace(/postcode=(.*?)(&|$)/, 'postcode=<postcode>$2');

const stripIds = compose(
  replaceUuids,
  replaceShortIds,
  replaceDates,
  replaceSearchPage,
  replacePostcode
);

export function getRequestVariable({ path, method = 'GET' }) {
  try {
    const [, url, , query = ''] = path.match(/^([^?]+)(\?(.*))?$/);

    return {
      variable: `${method} ${stripIds(url)}`,
      label: query ? stripIds(query) : null,
    };
  } catch {
    return { variable: null };
  }
}

export function trackPerformance(category, variable, value, label = null) {
  const params = {
    hitType: 'timing',
    category,
    variable,
    value: Math.round(value),
    label,
  };

  if (process.env.NODE_ENV === 'production') {
    try {
      ReactGA.send(params);
    } catch (e) {
      console.log(e);
    }
  } else {
    // eslint-disable-next-line no-console
    console.debug('[ga-timing]', JSON.stringify(params));
  }
}

export const trackChatToSupplierEvent = (Vendor) => {
  trackEvent(CHAT_TO_SUPPLIER, { Vendor });
};
