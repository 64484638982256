import React, { lazy, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { connect, Provider } from 'react-redux';
import * as themes from '@feast-it/pesto';
import { ThemeProvider } from 'emotion-theming';
import queryString from 'query-string';

import { resetMetadata } from './actions/metadata';
import LayoutSkeleton from './components-togather/layout-skeleton';
import { trackPageViewed } from './modules/analytics/analytics';
import { history as baseHistory } from './modules/history';
import { client } from './modules/apollo';
import { useUser } from './hooks/useUser';
import { GCLID, MSCLKID } from './constants/session-storage';
import paths from './constants/paths';
import { useCookieHubConsent } from './hooks/useCookieHubConsent';

const URLRedirect = lazy(() => import('./components-togather/url-redirect'));
const Dashboard = lazy(() => import('./containers-togather/pages/dashboard'));
const UserRoute = lazy(() => import('./router/user-route'));
const CustomerRoute = lazy(() => import('./router/customer-route'));
const VisitorRoute = lazy(() => import('./router/visitor-route'));
const Shell = lazy(() => import('./containers-togather/partials/shell'));
const Missing = lazy(() => import('./containers-togather/pages/missing'));
const Login = lazy(() =>
  import('./containers-togather/pages/LoginPage/LoginPage')
);
const SignUp = lazy(() =>
  import('./containers-togather/pages/SignUpPage/SignUpPage')
);
const CreatePassword = lazy(() =>
  import('./containers-togather/pages/CreatePasswordPage/CreatePasswordPage')
);
const ResetPassword = lazy(() =>
  import('./containers-togather/pages/ResetPasswordPage/ResetPasswordPage')
);
const ResetPasswordSuccess = lazy(() =>
  import(
    './containers-togather/pages/ResetPasswordSuccessPage/ResetPasswordSuccessPage'
  )
);
const Booking = lazy(() => import('./containers-togather/pages/booking'));
const PaymentConfirmation = lazy(() =>
  import('./containers-togather/pages/PaymentConfirmation/PaymentConfirmation')
);
const MyBookings = lazy(() =>
  import('./containers-togather/pages/my-bookings')
);
const MyPayments = lazy(() =>
  import('./containers-togather/pages/my-payments')
);
const MyMessages = lazy(() => import('./containers-togather/pages/messages'));
const Settings = lazy(() =>
  import('./containers-togather/pages/settings/settings')
);
const ExternalReviews = lazy(() =>
  import('./containers-togather/pages/external-reviews')
);
const MyFavourites = lazy(() =>
  import('./containers-togather/pages/my-favourites')
);
const MyQuote = lazy(() => import('./containers-togather/pages/my-quote'));
const ImpersonatePage = lazy(() =>
  import('./containers-togather/pages/ImpersonatePage')
);
// Supplier Referral Page needs to be moved to olympus
const SupplierRoute = lazy(() => import('./router/supplier-route'));
const SuppliersReferral = lazy(() =>
  import('./containers-togather/pages/suppliers-dashboard/referral')
);

const mapDispatchToProps = {
  onResetMetadata: resetMetadata,
};

const Routes = connect(
  null,
  mapDispatchToProps
)(({ onResetMetadata }) => {
  const location = useLocation();
  const queries = queryString.parse(location.search);
  const user = useUser();

  useCookieHubConsent({ queries });

  useEffect(() => {
    trackPageViewed(location);
  }, [location]);

  useEffect(() => {
    const gclid = queries?.gclid;
    const msclkid = queries?.msclkid;

    if (!sessionStorage.getItem(GCLID) && gclid) {
      sessionStorage.setItem(GCLID, gclid);
    }

    if (!sessionStorage.getItem(MSCLKID) && msclkid) {
      sessionStorage.setItem(MSCLKID, msclkid);
    }
  }, [queries]);

  // Reset scroll position and metadata when pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
    onResetMetadata();
  }, [location.pathname, onResetMetadata]);

  return !user ? (
    <LayoutSkeleton />
  ) : (
    <ThemeProvider theme={themes.togatherTheme}>
      <Shell>
        <Switch>
          <CustomerRoute path="/dashboard" component={Dashboard} />
          <UserRoute path="/my-bookings" component={MyBookings} />
          <UserRoute path="/booking/:bookingId/payment" component={Booking} />
          <UserRoute
            path="/payment/confirmation/:bookingId"
            component={PaymentConfirmation}
          />
          <UserRoute path="/messages" component={MyMessages} />
          <VisitorRoute path="/login" component={Login} />
          <VisitorRoute path="/signup" component={SignUp} />
          <Route
            path="/users/:userId/password/forgot/:token"
            component={CreatePassword}
          />
          <Route path="/reset-password" component={ResetPassword} />
          <Route
            path={paths.RESET_ENQUIRY_PASSWORD_SUCCESS}
            component={ResetPasswordSuccess}
          />
          <Route path="/change-password">
            <Redirect to="/reset-password" />
          </Route>
          <Route path="/recover-password">
            <Redirect to="/reset-password" />
          </Route>
          <CustomerRoute path="/my-payments" component={MyPayments} />
          <UserRoute path="/settings" component={Settings} />
          <Route path="/u/:urlId" component={URLRedirect} />
          <Route
            path="/my-quote/:quoteId"
            component={(props) => <MyQuote isPreview {...props} />}
          />
          <VisitorRoute
            exact
            path="/reviews/:reviewId"
            component={ExternalReviews}
          />
          <CustomerRoute path="/my-favourites" component={MyFavourites} />
          <SupplierRoute
            path="/suppliers/dashboard/refer"
            component={SuppliersReferral}
          />
          <Route
            path="/impersonate/:authorizationToken"
            component={ImpersonatePage}
          />
          <Route path="*" component={Missing} />
        </Switch>
      </Shell>
    </ThemeProvider>
  );
});

export function App({ store }) {
  return (
    <ApolloProvider client={client}>
      <Suspense fallback={<LayoutSkeleton />}>
        <Provider store={store}>
          <Router history={baseHistory}>
            <Routes />
          </Router>
        </Provider>
      </Suspense>
    </ApolloProvider>
  );
}

App.propTypes = {
  store: PropTypes.shape({}).isRequired,
};
