// Dependencies
import Prismic from 'prismic-javascript';

// Data
import config from '../../app-config';

export default class PrismicService {
  constructor() {
    this.api = Prismic.client(config.prismicUri, {
      accessToken: config.prismicKey,
    });
  }

  static queryAPI(api, type, id, options) {
    return api.query(Prismic.Predicates.at(type, id), options);
  }

  get(type, name, options) {
    return Promise.resolve(this.api)
      .then(api => PrismicService.queryAPI(api, type, name, options))
      .then(response => response);
  }
}
