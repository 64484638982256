// import { logoutUser } from '../ducks/users';
import { getRequestVariable, trackPerformance } from '../libs/analytics';

// const isUnauthorized = status => status === 401;
const isStatusOkay = (status) =>
  (status >= 200 && status < 300) || status === 304 || status === 400;

function createFetchingFunctionFromMiddleware(baseUri, httpMiddleware) {
  return async (request) => {
    const chain = [...httpMiddleware];

    async function next(req) {
      const handler = chain.shift();

      if (handler != null) {
        return handler(req, next);
      }
      const uri = baseUri + req.path;
      return fetch(uri, req);
    }

    return next(request);
  };
}

function createServiceError(responseBody) {
  const error = new Error(`SERVICE_CALL_ERROR - ${responseBody}`);
  error.response = responseBody;
  return error;
}

async function tryGetResponseBody(response) {
  try {
    return await response.json();
  } catch (err) {
    throw createServiceError(err);
  }
}

function trackFetchPerformance(request, loadTime) {
  const category = 'HTTP Service';
  const { variable, label } = getRequestVariable(request);
  if (!variable) {
    return null;
  }

  const value = loadTime;

  return trackPerformance(category, variable, value, label);
}

export default class HttpService {
  constructor(baseUri, httpMiddleware = [], store) {
    this.store = store;
    this.baseUri = baseUri;
    this.httpMiddleware = httpMiddleware;
    this.fetch = createFetchingFunctionFromMiddleware(baseUri, httpMiddleware);
  }

  async requestCore(request) {
    const timeStart = performance.now();

    const response = await this.fetch(request);

    const loadTime = performance.now() - timeStart;

    trackFetchPerformance(request, loadTime);

    // todo: review this
    if (response.status === 204) {
      return '';
    }
    const responseBody = await tryGetResponseBody(response);
    // if (isUnauthorized(response.status)) {
    //   return this.store.dispatch(logoutUser(false));
    // }

    if (!isStatusOkay(response.status)) {
      throw createServiceError(responseBody);
    }

    return responseBody;
  }

  async request(request) {
    const responseBody = await this.requestCore(request);
    if (responseBody == null) {
      return null;
    }
    return responseBody.data || responseBody;
  }
}
