import { ValueOf } from '../types/helpers';

export const COGNITO_IDENTITY_FIELDS = {
  DATABASE_ID: 'custom:databaseId',
  COGNITO_USERNAME: 'cognito:username',
  GROUPS: 'cognito:groups',
  USERNAME: 'username',
};

export const CUSTOMER_GROUP_TYPE = 'customer';
export const ADMIN_GROUP_TYPE = 'admin';
export const SUPPLIER_GROUP_TYPE = 'supplier';

const AUTH_PROVIDERS = {
  GOOGLE: 'Google',
} as const;

export type AuthProviders = ValueOf<typeof AUTH_PROVIDERS>;

export const GOOGLE_SSO_USERNAME_PREFIX = 'Google_';
