import queryString from 'query-string';
import moment from 'moment';

import {
  FETCH_SUPPLIERS,
  FETCH_SUPPLIER,
  NOT_FOUND_SUPPLIER,
} from '../constants/action-types';
import { httpService } from '../../index';
import config from '../../app-config';

export function fetchSuppliers({ page = 0, date, limit, ...filters }) {
  const query = queryString.stringify(
    {
      ...filters,
      limit: limit ?? config.resultsLimitVendors,
      date: date && moment(date).format('YYYY-MM-DD'),
    },
    { arrayFormat: 'bracket' }
  );

  return httpService.request({
    path: `/suppliers/browse/search/${page}?${query}`,
  });
}

export function fetchSupplierBySlug(slug) {
  return httpService.request({
    path: `/suppliers/slug/${slug}`,
  });
}

export function fetchSupplierTypes(id) {
  return httpService.request({
    path: `/suppliers/${id}/types`,
  });
}

export function fetchSupplier(id) {
  return httpService.request({
    path: `/suppliers-v2/${id}`,
  });
}

export const getSupplier = id => async dispatch =>
  dispatch({
    type: FETCH_SUPPLIER,
    payload: await fetchSupplier(id),
  });

export const getSupplierBySlug = slug => async dispatch => {
  let supplier = null;
  try {
    supplier = await fetchSupplierBySlug(slug);
  } catch {
    dispatch({ type: NOT_FOUND_SUPPLIER });
  }

  if (supplier) {
    dispatch({
      type: FETCH_SUPPLIER,
      payload: supplier,
    });
  }
};

export const getSuppliers = filters => async dispatch =>
  dispatch({
    type: FETCH_SUPPLIERS,
    payload: await fetchSuppliers(filters),
  });

export const getSupplierInfo = () => (dispatch, getState) => {
  const { user } = getState();
  const supplierId = user?.suppliers[0]?.uuid;

  if (supplierId) {
    httpService
      .request({ path: `/suppliers/supplier-info/${supplierId}` })
      .then(payload => {
        dispatch({ type: FETCH_SUPPLIER, payload });
      });
  }
};

export const getSupplierProfileActivity = id =>
  httpService.request({ path: `/suppliers/${id}/activity` });
