import parsePhoneNumber from 'libphonenumber-js/min';

export const isValidEmailAddress = email =>
  /* eslint-disable no-useless-escape */
  email &&
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
/* eslint-enable */

export const isValidPhoneNumber = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const phoneNumberIsValid = phoneNumber => {
  if (!phoneNumber) return false;

  try {
    const number = parsePhoneNumber(phoneNumber, 'GB');

    return number.isValid();
  } catch (error) {
    return false;
  }
};

export const formatPhoneNumberWithGBCountryCode = phoneNumber => {
  if (phoneNumber === null) {
    return '';
  }

  const parsedNumber = parsePhoneNumber(phoneNumber, 'GB');

  if (!parsedNumber) return '';

  return parsedNumber.number;
};

export const formatPhoneNumberWithNoCountryCode = phoneNumber => {
  const parsedNumber = parsePhoneNumber(phoneNumber, 'GB');

  if (!parsedNumber) return '';

  return 0 + parsedNumber.nationalNumber;
};

export const isValidPostcode = postcode => {
  const regex = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/i;
  return regex.test(postcode);
};

export const isValidPassword = password => password.length >= 8;

export const validations = {
  required: value => {
    if (!value) {
      return 'Required';
    }
    return null;
  },
  email: value => {
    if (!value) {
      return 'Required';
    }
    if (!isValidEmailAddress(value)) {
      return 'Incorrect email address';
    }
    return null;
  },
  password: value => {
    if (!value) {
      return 'Required';
    }
    if (!isValidPassword(value)) {
      return 'Must be at least 8 characters';
    }
    return null;
  },
  passwordConfirm: (password, passwordConfirm) => {
    if (password !== passwordConfirm) {
      return 'Passwords must match';
    }
    return null;
  },
  phone: value => {
    if (!value) {
      return 'Required';
    }

    if (value.length < 8) {
      return 'Must be at least 8 digits';
    }

    return null;
  },
  guests: value => {
    if (!value) {
      return "Let us know the number of guests, or provide a rough estimate if you're not sure yet";
    }

    return null;
  },
  perHead: value => {
    if (!value) {
      return 'This helps us find the quotes best suited for your budget';
    }

    return null;
  },
  budget: value => {
    if (!value) {
      return 'This helps us find the quotes best suited for your budget';
    }

    return null;
  },
  covers: value => {
    if (!value) {
      return 'Let us know the number of covers you are quoting for';
    }

    return null;
  },
  cuisineTypes: value => {
    if (!value || value.length === 0) {
      return "Tell us at least one cuisine you're interested in. You can always add or change these later";
    }

    return null;
  },
  supplierType: value => {
    if (!value || value.length === 0) {
      return 'Let us know at least one type of supplier you’re interested in';
    }

    return null;
  },
  date: value => {
    if (!value) {
      return 'We need your event date to check if suppliers are available';
    }

    return null;
  },
  line1: value => {
    if (!value) {
      return 'An event address means suppliers can plan their travel to you';
    }

    return null;
  },
  city: value => {
    if (!value) {
      return 'Your city helps us select relevant suppliers';
    }

    return null;
  },
  postcode: value => {
    if (!value) {
      return 'A postcode means suppliers can plan their travel to you';
    }

    if (!isValidPostcode(value)) {
      return 'Please enter a valid postcode';
    }

    return null;
  },
  supportText: value => {
    const trimmedValue = value ? value.replace('<p><br></p>', '') : '';

    if (!trimmedValue) {
      return "Please help the client with details of what you'll offer for the quoted price";
    }

    return null;
  },
  menuText: value => {
    const trimmedValue = value ? value.replace('<p><br></p>', '') : '';

    if (!trimmedValue) {
      return "Please let the client know what's included for your quote price";
    }

    return null;
  },
  menuPrice: value => {
    if (!Number(value)) {
      return 'Please provide a price per head';
    }

    return null;
  },
  bookingDate: value => {
    if (!value) {
      return 'This helps us get you supplier quotes as quickly as possible.';
    }

    return null;
  },
};

export function keyValidator(validationSet) {
  return Object.keys(validationSet).reduce((acc, key) => {
    if (validationSet[key]) {
      acc[key] = validationSet[key];
    }
    return acc;
  }, {});
}
