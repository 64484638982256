import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import PropTypes from 'prop-types';

interface ReactRouterHref {
  pathname?: string;
  search?: string;
  hash?: string;
  state?: any;
}

const isURL = (path: string) => /^https?:\/\//.test(path);

interface LinkProps {
  href: string | ReactRouterHref;
  [x: string]: any;
}

const Link: React.FC<LinkProps> = ({ href, ...otherProps }) => {
  if (typeof href === 'string' && isURL(href)) {
    return (
      <a href={href} {...otherProps} /> // eslint-disable-line jsx-a11y/anchor-has-content
    );
  }

  return <ReactLink to={href} activeClassName="active" {...otherProps} />;
};
Link.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
  ]).isRequired,
};

export default Link;
