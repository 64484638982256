import moment from 'moment';
import striptags from 'striptags';
import compose from 'just-compose';
import qs from 'query-string';

moment.updateLocale('en', {
  relativeTime: {
    past: (diff) => (diff === 'just now' ? diff : `${diff} ago`),
    s: 'just now',
  },
});

export const formatNumber = (num) => (num ? num.toLocaleString('en-GB') : '0');

export function truncateDecimals(number, numOfDecimals) {
  const isNumber = typeof number === 'number';
  const numberAsString = number.toString();
  const numberArray = numberAsString.split('.');
  const base = numberArray[0];
  let decimals = numberArray[1] || '';

  if (isNaN(number) || isNaN(numOfDecimals)) {
    return '0';
  }

  if (!decimals) {
    return isNumber ? Number(number) : number;
  }

  if (Number(numOfDecimals) === 0) {
    const value = Math.trunc(number);
    return isNumber ? value : value.toString();
  }

  const { length } = decimals;
  const excess = length - numOfDecimals;

  decimals = excess > 0 ? decimals.slice(0, length - excess) : decimals;

  const formattedNumber = [base, decimals].join('.');
  const truncatdNumber = isNumber ? Number(formattedNumber) : formattedNumber;

  return truncatdNumber;
}

export const formatCurrency = (num) =>
  num
    ? num.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' })
    : '£0.00';

export function arrayToList(array, key) {
  return array.map((elem) => elem[key]).join(', ');
}

export function getDuration(startTime, endTime) {
  if (startTime === endTime) return 24;
  const now = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
  const hoursApart = moment.duration(end.diff(now)).asHours();
  const duration = hoursApart < 0 ? 24 + hoursApart : hoursApart;
  return duration;
}

export function getWorkingHours(startTime, endTime, maxWorkingHours) {
  const eventDuration = getDuration(startTime, endTime);
  return eventDuration > maxWorkingHours ? maxWorkingHours : eventDuration;
}

export function formatForSelect(arr) {
  return arr.map((item) => ({
    label: item.name,
    value: item.id,
  }));
}

export function formatFromNowTime(date, options = { short: true }) {
  return moment(date).diff(moment.now(), 'days') <= -1
    ? moment(date).format('D MMMM')
    : moment(date).fromNow(options.short);
}

export const convertPathToName = (path) => {
  const str = path.charAt(0) === '/' ? path.substring(1) : path;

  if (!str.length) {
    return 'Homepage';
  }
  return str
    .replace(/-/gi, ' ')
    .replace(/\//gi, ' - ')
    .replace(/^\w/, (c) => c.toUpperCase());
};

export const truncateText = (charLimit, ellipsis = false) => (text) => {
  let truncatedText = text;

  if (truncatedText && truncatedText.length >= charLimit) {
    truncatedText = truncatedText.substr(0, charLimit).trim();

    const disallowedChars = [' ', ',', '.', '/', '?', '!', '&', '-'];
    const lastChar = truncatedText.substr(-1);

    if (disallowedChars.includes(lastChar)) {
      truncatedText = truncatedText.substr(0, truncatedText.length - 1).trim();
    }

    truncatedText += ellipsis ? '&mldr;' : '';
  }

  return truncatedText;
};

export const stripTagsAndTruncate = (text, charLimit, ellipsis) =>
  compose(striptags, truncateText(charLimit, ellipsis))(text);

export const stripHtmlAndTruncateText = (
  text,
  charlLimit,
  ellipsis = false
) => {
  let truncatedText = striptags(text);

  if (truncatedText && truncatedText.length >= charlLimit) {
    truncatedText = truncatedText.substr(0, charlLimit);
    truncatedText += ellipsis ? '&mldr;' : '';
  }
  return truncatedText;
};

export function stripHtmlAndTruncate(text, maxLength) {
  if (!text) return;
  const plainText = text.replace(/<[^>]*>?/gm, ' ');

  if (maxLength && plainText.length > maxLength) {
    return `${plainText.substring(0, maxLength).trim()}...`;
  }
  return plainText.trim();
}

export const replaceString = (string, target, replacement) => {
  if (
    typeof string !== 'string' ||
    typeof target !== 'string' ||
    typeof replacement !== 'string'
  ) {
    return string;
  }
  const regex = new RegExp(target, 'g');
  return string.replace(regex, replacement);
};

export const onlyAlpahChars = (string) => {
  const text = string || '';
  return text
    .replace(/[^a-zA-Z]/g, ' ')
    .replace(/\s\s+/g, ' ')
    .trim();
};

export const convertTextToInitials = (rawText, limit = 2) => {
  const newText = onlyAlpahChars(rawText);
  const text = newText || '';
  const namesArray = text.split(' ');
  namesArray.length = limit;

  const initialsArray = namesArray.map((name) =>
    name[0] ? name[0].toUpperCase() : ''
  );
  return initialsArray.join('');
};

export function capitalizeFirstLetter(string) {
  if (string && string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return string;
}

export const initializeSurname = (rawText) => {
  const newText = onlyAlpahChars(rawText);
  const text = newText || '';
  const namesArray = text.split(' ');
  const CapitatizedFirstname = capitalizeFirstLetter(namesArray[0]);
  const surname = namesArray[1] || '';
  const surnameInitial = surname[0] ? surname[0].toUpperCase() : '';
  const name = `${CapitatizedFirstname} ${surnameInitial}`;
  return name.trim();
};

export const stringifyURL = (url, query) =>
  qs.stringifyUrl({ url, query }, { arrayFormat: 'bracket' });

export const removeStyleAttrFromString = (rawText) => {
  if (typeof rawText !== 'string') {
    return null;
  }
  if (rawText.includes('style="')) {
    return rawText.replace(/ style="[^"]*"/g, '');
  }
  return rawText;
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return null;

  const cleansedPhoneNumber = phoneNumber.replace(/[\s\-()]/g, '');

  const prefixMatch = phoneNumber.match(/^(\+[\d]{1,4})/);

  if (prefixMatch) return cleansedPhoneNumber;

  return `+44${cleansedPhoneNumber.slice(1, cleansedPhoneNumber.length)}`;
};
