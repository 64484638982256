function indexObject(obj, path) {
  const propArray = path.split('.');
  const indexedVal = propArray.reduce((acc, val) => acc[val], obj);
  return indexedVal;
}

export const uniq = array => [...new Set(array)];
export const sortBy = (array, key) =>
  array.sort((a, b) => {
    let rule;
    if (a[key] > b[key]) {
      rule = 1;
    } else if (b[key] > a[key]) {
      rule = -1;
    } else {
      rule = 0;
    }
    return rule;
  });

export const orderBy = (array, key, order) =>
  array.sort((a, b) => {
    const aItem = typeof key === 'function' ? key(a) : indexObject(a, key);
    const bItem = typeof key === 'function' ? key(b) : indexObject(b, key);
    let direction = 1;

    if (order === 'desc') {
      direction = -1;
    }

    let rule;
    if (aItem > bItem) {
      rule = direction;
    } else if (bItem > aItem) {
      rule = -direction;
    } else {
      rule = 0;
    }
    return rule;
  });

/* eslint-disable no-param-reassign */
export const groupBy = (array, condition) =>
  array.reduce((r, v) => {
    const group = typeof condition === 'function' ? condition(v) : v[condition];
    (r[group] || (r[group] = [])).push(v);
    return r;
  }, {});
/* eslint-enable */

export const union = (...arrays) => {
  let unionOfArrays = [];
  arrays.forEach(array => {
    unionOfArrays.push(...array);
  });
  unionOfArrays = [...new Set(unionOfArrays)];
  return unionOfArrays;
};

export const unionBy = (...arrays) => {
  const criteria = arrays.pop();
  const criteriaFunc =
    typeof criteria === 'string' ? ele => ele[criteria] : criteria;
  const combinedArrays = [];
  arrays.forEach(array => {
    combinedArrays.push(...array);
  });
  const unionOfArrays = combinedArrays.reduce((acc, val) => {
    const shouldAddValue = !acc.some(
      ele => criteriaFunc(ele) === criteriaFunc(val)
    );
    if (shouldAddValue) {
      acc.push(val);
    }
    return acc;
  }, []);
  return unionOfArrays;
};

export const isEqual = (object1, object2) =>
  JSON.stringify(object1) === JSON.stringify(object2);

export const replaceAtIndex = (array, index, value) => {
  if (index === -1) {
    return array;
  }

  const nextValue = typeof value === 'function' ? value(array[index]) : value;

  return [...array.slice(0, index), nextValue, ...array.slice(index + 1)];
};

export const removeAtIndex = (array, index) => {
  if (index === -1) {
    return array;
  }

  return [...array.slice(0, index), ...array.slice(index + 1)];
};

// https://gist.github.com/isthatcentered/b5fa657aa3ec90790d34f3c138b58839
/**
 * debounce
 * Ensures a function won't be called before a defined amout of time
 * Ex:
 *    on window resize, ensure a function won't be called
 *    until the user stopped resizing window for {time param}
 *
 * @param { function } fn Callback to be executed after debounce
 * @param { int } time Time to wait before function execution
 * @return {function(...[*])}
 */
export const debounce = (fn, time = 300) => {
  // Store active timeout
  let timeout;

  return (...args) => {
    // Clear active timeout to prevent fn execution
    clearTimeout(timeout);

    // Start a new timeout
    timeout = setTimeout(fn.bind(null, ...args), time);
  };
};

export function getChangedValues(values, initialValues) {
  return Object.entries(values).reduce(
    (changedValues, [valueKey, value]) =>
      initialValues[valueKey] !== value
        ? {
            ...changedValues,
            [valueKey]: value,
          }
        : changedValues,
    {}
  );
}
