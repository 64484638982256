import {
  FETCH_QUOTE,
  ACCEPT_QUOTE,
  DECLINE_QUOTE,
  CREATE_QUOTE,
} from '../constants/action-types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_QUOTE:
    case ACCEPT_QUOTE:
    case DECLINE_QUOTE:
      return { ...action.payload };
    case CREATE_QUOTE:
      return state;
    default:
      return state;
  }
}
