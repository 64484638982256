export const SOCKET_READY = 'io/READY';

export const SHELL_SET_SETTINGS = 'SHELL_SET_SETTINGS';
export const AUTHENTICATION_LOGIN = 'AUTHENTICATION_LOGIN';
export const IDENTITY_CHANGED = 'IDENTITY_CHANGED';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_HEADER_COLOR = 'SET_HEADER_COLOR';

export const FETCH_SUPPLIERS = 'FETCH_SUPPLIERS';
export const FETCH_SUPPLIER = 'FETCH_SUPPLIER';
export const NOT_FOUND_SUPPLIER = 'NOT_FOUND_SUPPLIER';

// New quote/booking actions, triggering sagas
export const QUOTE_BOOKED_NOW = 'QUOTE_BOOKED_NOW';

export const FETCH_BOOKINGS = 'FETCH_BOOKINGS';
export const FETCH_BOOKING = 'FETCH_BOOKING';
export const ACCEPT_BOOKING = 'ACCEPT_BOOKING';
export const DECLINE_BOOKING = 'DECLINE_BOOKING';
export const UPDATE_BOOKING = 'UPDATE_BOOKING';
export const BOOKING_PRICE_UPDATED = 'BOOKING_PRICE_UPDATED';

export const BOOKING_CONVERSATION_REDIRECTED =
  'BOOKING_CONVERSATION_REDIRECTED';

export const FETCH_REQUEST_SUMMARY = 'FETCH_REQUEST_SUMMARY';
export const FETCH_SUPPLIER_REQUESTS = 'FETCH_SUPPLIER_REQUESTS';
export const SET_HIDDEN_REQUEST_ANALYTICS = 'SET_HIDDEN_REQUEST_ANALYTICS';

export const FETCH_QUOTES = 'FETCH_QUOTES';
export const FETCH_QUOTE = 'FETCH_QUOTE';
export const FETCH_QUOTE_ID = 'FETCH_QUOTE_ID';
export const ACCEPT_QUOTE = 'ACCEPT_QUOTE';
export const DECLINE_QUOTE = 'DECLINE_QUOTE';
export const CREATE_QUOTE = 'CREATE_QUOTE';
export const CREATE_SUPPLIER_QUOTE = 'CREATE_SUPPLIER_QUOTE';

export const SET_EVENT_TOTAL = 'SET_EVENT_TOTAL';
export const SET_EVENT = 'SET_EVENT';
export const RESET_EVENT = 'RESET_EVENT';
export const SET_EVENT_BOOKING_ID = 'SET_EVENT_BOOKING_ID';
export const SET_PERCENTAGE_VALUE = 'SET_PERCENTAGE_VALUE';

export const SET_METADATA = 'SET_METADATA';
export const RESET_METADATA = 'RESET_METADATA';

export const FETCH_SETTING = 'FETCH_SETTING';
export const RECEIVED_SETTING = 'RECEIVED_SETTING';
export const SET_SETTING = 'SET_SETTING';

export const FETCH_TAGS = 'FETCH_TAGS';
export const RECEIVED_TAGS = 'RECEIVED_TAGS';

export const GET_SUPPLIER_TAGS = 'GET_SUPPLIER_TAGS';
export const RECEIVED_SUPPLIER_TAGS = 'RECEIVED_SUPPLIER_TAGS';

export const INIT_FILTER = 'INIT_FILTER';
export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER';

export const FETCH_DYNAMIC_LANDINGS = 'FETCH_DYNAMIC_LANDINGS';
export const FETCH_DYNAMIC_LANDING = 'FETCH_DYNAMIC_LANDING';
export const FETCH_SITEMAP = 'FETCH_SITEMAP';
export const FETCH_PRISMIC_SETTINGS = 'FETCH_PRISMIC_SETTINGS';

export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
export const FETCH_PAYMENT = 'FETCH_PAYMENT';

export const UPDATE_USER_TRACK = 'UPDATE_USER_TRACK';

export const FETCH_OPEN_EVENTS = 'FETCH_OPEN_EVENTS';
export const FETCH_TOTAL_OPEN_EVENTS = 'FETCH_TOTAL_OPEN_EVENTS';
export const TOGGLE_NOTIFICATION = 'TOGGLE_NOTIFICATION';
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION';

export const FETCH_REFERRALS = 'FETCH_REFERRALS';
export const REFERRAL_SHARE_TRIGGERED = 'REFERRAL_SHARE_TRIGGERED';

export const QUOTE_TEMPLATE_CREATED = 'QUOTE_TEMPLATE_CREATED';
export const QUOTE_TEMPLATES_READ = 'QUOTE_TEMPLATES_READ';
export const QUOTE_TEMPLATE_DELETED = 'QUOTE_TEMPLATE_DELETED';
export const QUOTE_TEMPLATE_UPDATED = 'QUOTE_TEMPLATE_UPDATED';
export const QUOTE_TEMPLATE_ERRORED = 'QUOTE_TEMPLATE_ERRORED';

export const SUPPLIER_PAGINATED_REVIEW_LIST_REQUESTED =
  'supplierReviews/PAGINATED_LIST_REQUESTED';
export const SUPPLIER_REVIEW_LIST_RECEIVED = 'supplierReviews/LIST_RECEIVED';
export const SUPPLIER_REVIEW_CREATED = 'supplierReviews/CREATED';
export const SUPPLIER_REVIEW_READ = 'supplierReviews/READ';
export const SUPPLIER_REVIEW_UPDATED = 'supplierReviews/UPDATED';
export const SUPPLIER_REVIEW_AUTHENTICATED = 'supplierReviews/AUTHENTICATED';
export const SUPPLIER_REVIEW_RECEIVED = 'supplierReviews/RECEIVED';
export const SUPPLIER_PAGINATED_REVIEW_LIST_RECEIVED =
  'supplierReviews/PAGINATEDç_LIST_RECEIVED';
export const SUPPLIER_REVIEW_ERRORED = 'supplierReviews/ERRORED';

export const ON_CALL_STARTED = 'calling/ON_CALL_STARTED';
export const CALL_STARTED = 'calling/CALL_STARTED';
export const ON_RESET_CALL_STATE = 'calling/ON_RESET_CALL_STATE';
export const RESET_CALL_STATE = 'calling/RESET_CALL_STATE';

export const FETCH_CUSTOMER_EVENTS = 'FETCH_CUSTOMER_EVENTS';
export const GET_ALL_CUSTOMER_EVENTS = 'GET_ALL_CUSTOMER_EVENTS';
export const FETCH_CUSTOMER_EVENT = 'FETCH_CUSTOMER_EVENT';
export const GET_CUSTOMER_EVENT = 'GET_CUSTOMER_EVENT';
export const RESET_CUSTOMER_EVENT = 'RESET_CUSTOMER_EVENT';

export const GET_ALL_EVENT_REQUESTS = 'GET_ALL_EVENT_REQUESTS';
export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST';
export const FETCH_EVENT_REQUESTS = 'FETCH_EVENT_REQUESTS';
export const RESET_EVENT_REQUESTS = 'RESET_EVENT_REQUESTS';
export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST';
export const FETCH_EVENT_REQUEST_START = 'FETCH_EVENT_REQUEST_START';
export const GET_EVENT_REQUEST_ROBEX = 'GET_EVENT_REQUEST_ROBEX';
export const FETCH_EVENT_REQUEST_ROBEX = 'FETCH_EVENT_REQUEST_ROBEX';
export const GET_EVENT_CROSS_SELL = 'GET_EVENT_CROSS_SELL';
export const FETCH_EVENT_CROSS_SELL = 'FETCH_EVENT_CROSS_SELL';
export const GET_EVENT_REQUEST_UNREAD_MESSAGES =
  'GET_EVENT_REQUEST_UNREAD_MESSAGES';
export const FETCH_EVENT_REQUEST_UNREAD_MESSAGES =
  'FETCH_EVENT_REQUEST_UNREAD_MESSAGES';
export const RESET = 'RESET';
export const FETCH_EVENT_REQUEST_ERROR = 'FETCH_EVENT_REQUEST_ERROR';
