import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';

import booking from './booking';
import bookings from './bookings';
import bookingProtection from '../ducks/booking-protection';
import breadcrumbs from '../ducks/breadcrumbs';
import dynamicLandings from './dynamic-landings';
import conversations from './conversations';
import requestSummary from './request';
import requests from './requests';
import event from './event';
import events from './events';
import eventCrossSell from './event-cross-sell';
import customerEvent from './customer-event';
import customerEvents from './customer-events';
import eventRequest from './event-request';
import eventRequests from './event-requests';
import filters from './filters';
import header from './header';
import metadata from './metadata';
import modal from './modal';
import payments from '../ducks/payments';
import quote from './quote';
import quoteTemplates from './quote-templates';
import quotes from './quotes';
import referrals from './referrals';
import reviews from '../ducks/reviews';
import settings from './settings';
import supplierReviews from './supplier-reviews';
import tags from './tags';
import notifications from './notifications';
import user from '../ducks/users';
import vendor from './vendor';
import vendors from './vendors';
import supplierCalendar from '../ducks/supplier-calendar';
import calling from './calling';
import supplierTags from './supplier-tags';

export default combineReducers({
  form: formReducer,
  booking,
  bookings,
  bookingProtection,
  breadcrumbs,
  dynamicLandings,
  conversations,
  requests,
  requestSummary,
  event,
  eventCrossSell,
  events,
  filters,
  header,
  metadata,
  modal,
  payments,
  quote,
  quotes,
  referrals,
  reviews,
  settings,
  supplierReviews,
  tags,
  notifications,
  vendor,
  vendors,
  user,
  quoteTemplates,
  supplierCalendar,
  calling,
  customerEvent,
  customerEvents,
  eventRequest,
  eventRequests,
  supplierTags,
});
